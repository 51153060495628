/* ==========================================================================
   #BUTTONS
   ========================================================================== */
@import 'mixins.scss';
@import 'tokens.scss';


/**
 * HTML

  Anchor:
    <a href="#0" id="btnLink" class="c-btn c-btn--primary" role="button">Link</a>

  Button:
    <button id="btnButton" class="c-btn c-btn--primary">Button</button>

  Input:
    <input id="btnSubmit" class="c-btn c-btn--primary" type="submit" value="Submit""/>

 *
 */

/**
 * 1. Mobile first - make our buttons block elements below tablet size screens,
 *    center align them, and make them full width up to a set max-width.
 * 2. Allow us to style box model properties.
 * 3. Line different sized buttons up a little nicer.
 * 4. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 5. Reset/normalize some styles.
 * 6. Force all button-styled elements to appear clickable.
 * 7. Give the user a nice visual indication that the button is in focus.
 * 8. Make the default button have small rounded corners for the box-shadow outline.
 */
.c-btn {
  position: relative;
  display: block; /* [1] */
  align-items: center;
  justify-content: center;
  width: 100%; /* [1] */
  // height: --aura-c-button-height;
  max-width:      var(--aura-c-button-max-width, $button-max-width-mobile); /* [1] */
  // margin-bottom:  $spacing-default; /* [1] */
  margin-right:   auto; /* [1] */
  margin-left:    auto; /* [1] */
  padding-top:    var(--aura-c-button-padding-block-start-mobile, $button-padding-block-start-mobile);  /* [1] */
  padding-bottom: var(--aura-c-button-padding-block-end-mobile, $button-padding-block-end-mobile);  /* [1] */
  padding-left:   var(--aura-c-button-padding-inline-start-mobile, 0);
  padding-right:  var(--aura-c-button-padding-inline-end-mobile, 0);
  font: inherit; /* [4] */
  // text-align: center; /* [5] */
  @include font-size($font-size-button, $button-line-height-mobile);
  font-weight: var(--aura-c-button-font-weight, $font-weight-button);
  letter-spacing: var(--aura-c-button-letter-spacing, $button-letter-spacing);
  background: none;
  background-color: var(--aura-c-button-color-background, transparent);
  background-clip: border-box;
  cursor: pointer; /* [6] */
  border-color: var(--aura-c-button-color-border, transparent);
  border-style: solid;
  border-width: var(--aura-c-button-border-width, $button-border-width);
  border-radius: var(--aura-c-button-radius-border, $button-border-radius); /* [8] */
  border-collapse: separate; /* For IE */
  -webkit-box-shadow: none;
          box-shadow: none;
  transition: var(--aura-c-button-transition, $button-transition);
  @include appearance(none);
  white-space: normal;
  user-select: none;

  @include all-anchor-states() {
    color: var(--aura-c-button-text-color, $color-text-link);
    text-decoration: none;
  }

  @include hocus() {
    outline: 0;
    cursor: pointer;
    color: var(--aura-c-button-text-color-hover, $color-text-link-hover);
  }

  &:hover {
    box-shadow: var(--aura-c-button-shadow-hover, none);
  }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-shadow-focus, $button-shadow-focus);
  }

  &:active {
    color: var(--aura-c-button-text-color-active, $color-text-link-active);
  }

  &:disabled,
  &.is-disabled,
  &[disabled],
  &[disabled="disabled"] {
    transition: none;

    @include all-anchor-states() {
      color: var(--aura-c-button-text-color-disabled, $color-text-link);
      cursor: default !important;
      background-image: none !important;
      background-color: transparent;
      box-shadow: none !important;
      opacity: var(--aura-c-button-opacity-disabled, $opacity-disabled);
    }

    * {
      pointer-events: none;
    }

  }

  a {
    color: currentColor;
  }

  @include mq($from: tablet) {
    // display: inline-block; /* [2] */
    // vertical-align: middle; /* [3] */
    // margin-left:    0; /* [5] */
    // margin-bottom:  0; /* [5] */
    // margin-right:   0; /* [5] */
    padding-top:    var(--aura-c-button-padding-block-start, $button-padding-block-start);  /* [1] */
    padding-bottom: var(--aura-c-button-padding-block-end, $button-padding-block-end);  /* [1] */
    padding-left:   var(--aura-c-button-padding-inline-start, 0);  /* [1] */
    padding-right:  var(--aura-c-button-padding-inline-end, 0);  /* [1] */
    width: auto;
    max-width: initial;
    // height: var(--aura-c-button-height, $c-button-height);
    line-height: var(--aura-c-button-height, $button-height);
    white-space: nowrap;
    // min-width: --aura-c-button-min-width;
  }


  /**
   * When `c-btn` classes are on a <label> wrapping an input button
   * 1. Set pointer-events to none to fix issue with the parent container's
   *    :pseudo or positioned elements interfering with the submmit action of the
   *    input button.
   */
   &.is-input-btn {

    &,
    &:hover,
    &:active,
    .c-svg {
      pointer-events: none; /* [1] */
    }

  }

  // Add spacing when another button is adjacent to it in the same parent container.
  + .c-btn {
    margin-left: $spacing-small;
  }

}


// a.c-btn {
//   text-align: center;

//   &:not(.c-btn--neutral, .c-btn--inverse, .c-btn--outline-neutral, .c-btn--outline-inverse) {
//     &:focus {
//       outline: 0;
//       box-shadow: var(--aura-c-button-shadow-focus, $button-shadow-focus);
//     }
//   }
// }


// Set up an input type="button|submit|reset|search|file" to be styled inside a `.c-btn` container
input.c-btn,
.c-btn__input {
  @include appearance(none);
  outline: none;
}

.c-btn__input {
  @include position-center();
  display: block;
  width:  100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  background: none;
  border: none;
  border-radius: var(--aura-c-button-radius-border, $button-border-radius);
  cursor: pointer;
  pointer-events: all;
  opacity: 0;
}




/* Style variants
   ========================================================================== */

// Reset button styles to browser default
.c-btn--reset {
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  padding: 0;
  background: transparent;
  border: 0;
  text-align: inherit;
}


.c-btn--primary {
  @include button-spacing-inline();
  background-color: var(--aura-c-button-primary-color-background, $button-primary-color-background);
  border-color: var(--aura-c-button-primary-color-border, $button-primary-color-border);

  @include all-anchor-states() {
    color: var(--aura-c-button-primary-text-color, $button-primary-color-text);
  }

  @include hocus() {
    background-color: var(--aura-c-button-primary-color-background-hover, $button-primary-color-background-hover);
    border-color: var(--aura-c-button-primary-color-border-hover, $button-primary-color-border-hover);
    color: var(--aura-c-button-primary-text-color-hover, $button-primary-color-text);
  }

  &:active {
    background-color: var(--aura-c-button-primary-color-background-active, $button-primary-color-background-active);
    border-color: var(--aura-c-button-primary-color-border-active, $button-primary-color-border-active);
    color: var(--aura-c-button-primary-text-color-active, $button-primary-color-text);
  }

  &:disabled,
  &.is-disabled,
  &[disabled],
  &[disabled="disabled"] {

    @include all-anchor-states() {
      background-color: var(--aura-c-button-primary-color-background-disabled, $button-primary-color-background);
      border-color: var(--aura-c-button-primary-color-border-disabled, $button-primary-color-border);
      color: var(--aura-c-button-primary-text-color-disabled, $button-primary-color-text);
    }

  }

}


.c-btn--outline,
.c-btn--secondary {
  @include button-spacing-inline();
  background-color: var(--aura-c-button-outline-color-background, transparent);
  border-color: var(--aura-c-button-outline-color-border, $button-primary-color-border);

  @include all-anchor-states() {
    color: var(--aura-c-button-outline-text-color, $button-outline-primary-color-text);
  }

  &:hover,
  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    background-color: var(--aura-c-button-outline-color-background-hover, transparent);
    border-color: var(--aura-c-button-outline-color-border-hover, $button-primary-color-border-hover);
    color: var(--aura-c-button-outline-text-color-hover, $button-outline-primary-color-text);
  }

  &:active {
    background-color: var(--aura-c-button-outline-color-background-active, transparent);
    border-color: var(--aura-c-button-outline-color-border-active, $button-primary-color-border-active);
    color: var(--aura-c-button-outline-text-color-active, $button-outline-primary-color-text);
  }

  &:disabled,
  &[disabled],
  &.is-disabled,
  &[disabled="disabled"] {
    @include all-anchor-states() {
      background-color: var(--aura-c-button-outline-color-background-disabled, transparent);
      border-color: var(--aura-c-button-outline-color-border-disabled, $button-primary-color-border);
      color: var(--aura-c-button-outline-text-color-disabled, $button-outline-primary-color-text);

      // Hide inner border
      &::before {
        display: none;
      }

    }
  }

  &:hover {
    box-shadow: var(--aura-c-button-outline-shadow-hover, $button-outline-inset-shadow-hover);
  }

}


.c-btn--neutral {
  @include button-spacing-inline();

  background-color: var(--aura-c-button-neutral-color-background, $button-neutral-color-background);
  border-color: var(--aura-c-button-neutral-color-border, $button-neutral-color-border);

  @include all-anchor-states() {
    color: var(--aura-c-button-neutral-text-color, $button-neutral-color-text);
  }

  &:hover,
  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    background-color: var(--aura-c-button-neutral-color-background-hover, $button-neutral-color-background-hover);
    border-color: var(--aura-c-button-neutral-color-border-hover, $button-neutral-color-border-hover);
    color: var(--aura-c-button-neutral-text-color-hover, $button-neutral-color-text);
  }

  &:active {
    background-color: var(--aura-c-button-neutral-color-background-active, $button-neutral-color-background-active);
    border-color: var(--aura-c-button-neutral-color-border-active, $button-neutral-color-border-active);
    color: var(--aura-c-button-neutral-text-color-active, $button-neutral-color-text);
  }

  &:disabled,
  &.is-disabled,
  &[disabled],
  &[disabled="disabled"] {

    @include all-anchor-states() {
      background-color: var(--aura-c-button-neutral-color-background-disabled, $button-neutral-color-background);
      border-color: var(--aura-c-button-neutral-color-border-disabled, $button-neutral-color-border);
      color: var(--aura-c-button-neutral-text-color-disabled, $button-neutral-color-text);
    }

  }

}


.c-btn--outline-neutral {
  @include button-spacing-inline();
  background-color: var(--aura-c-button-outline-neutral-color-background, transparent);
  border-color: var(--aura-c-button-outline-neutral-color-border, $button-neutral-color-border);

  @include all-anchor-states() {
    color: var(--aura-c-button-outline-neutral-text-color, $button-outline-neutral-color-text);
  }

  &:hover,
  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    background-color: var(--aura-c-button-outline-color-background-hover, transparent);
    border-color: var(--aura-c-button-outline-neutral-color-border-hover, $button-neutral-color-border-hover);
    color: var(--aura-c-button-outline-neutral-text-color-hover, $button-outline-neutral-color-text);
  }

  &:active {
    background-color: var(--aura-c-button-outline-neutral-color-background-active, transparent);
    border-color: var(--aura-c-button-outline-neutral-color-border-active, $button-neutral-color-border-active);
    color: var(--aura-c-button-outline-neutral-text-color-active, $button-outline-neutral-color-text);
  }

  &:disabled,
  &[disabled],
  &.is-disabled,
  &[disabled="disabled"] {
    @include all-anchor-states() {
      background-color: var(--aura-c-button-outline-neutral-color-background-disabled, transparent);
      border-color: var(--aura-c-button-outline-neutral-color-border-disabled, $button-neutral-color-border);
      color: var(--aura-c-button-outline-neutral-text-color-disabled, $button-outline-neutral-color-text);

      // Hide inner border
      &::before {
        display: none;
      }

    }
  }

  &:hover {
    box-shadow: var(--aura-c-button-outline-neutral-shadow-hover, $button-outline-neutral-inset-shadow-hover);
  }

}

.c-btn--neutral,
.c-btn--outline-neutral {

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-neutral-shadow-focus, $button-neutral-shadow);
  }

}

// Looks like text but treated like a button
.c-btn--text {
  width: auto;
  letter-spacing: initial;
  font-weight: var(--aura-c-button-text-font-weight, $font-weight-medium);
  // transition: $text-color-transition;
  border-radius: var(--aura-c-button-text-radius-border, $border-radius-small);

  &.is-inline,
  &.is-back-btn {
    display: inline-block;
    margin: 0;
  }

  &.is-inline {
    height: auto;
    line-height: inherit;
  }

  &.has-icon-bubble {
    padding: 0;
    padding-left: var(--aura-c-button-text-padding-inline-icon, $spacing-large);
    font-weight: var(--aura-c-button-font-weight, $font-weight-button);
    max-width: fit-content;

    @include all-anchor-states() {
      color: var(--aura-c-button-text-text-color, $color-text-default);
    }

    @include hocus() {
      color: var(--aura-c-button-text-text-color-hover, $color-black);
    }
  }

}

.c-btn__icon-bubble {
  display: block;
  @include position-y-center();
  left: 0;
  width:  var(--aura-c-button-icon-bubble-boundary, $round-icon-boundary);
  height: var(--aura-c-button-icon-bubble-boundary, $round-icon-boundary);
  background-color: var(--aura-c-button-icon-bubble-color-background, $resmed-brand-gray-palette-50);
  border-radius: $border-radius-circle;
  transition: $background-color-transition;

  .c-svg {
    @include position-center();
    width:  var(--aura-c-button-icon-size, $icon-size-default);
    height: var(--aura-c-button-icon-size, $icon-size-default);
    color: currentColor;
    background-color: transparent;
  }

  @at-root .c-btn--text:hover #{&} {
    background-color: var(--aura-c-button-text-icon-bubble-background-color-hover, $button-primary-color-background-hover);
    color: var(--aura-c-button-text-icon-bubble-background-color-hover, $color-white);
  }
}

.c-btn--skip-to-content {
  @include mq($until: desktop) {
    display: none;
  }

  @include button-spacing-inline();
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -101%);
  transition: transform 0.3s;
  z-index: 100;

  &:focus {
    transform: translate(-50%, 24px);
  }

  &.c-btn--secondary,
  &.c-btn--tertiary {
    background-color: var(--aura-c-button-skip-color-background, $color-white);
  }
}



/**
 * An icon only button butted up to a form input, like a search control.
 *
 * 1. Reset inherited `c-btn` styles that will mess up the combo button.
 * 2. Default button width that fits an icon.
 * 3. Match the height of input fields.
 * 4. Hide any button text that might be there for screen-readers etc.
 *    put it in a `span` tag.
 *
 * HTML:

    <div class="c-form-field">
      <label class="c-form-field__label">Search</label>
      <div class="c-form-field__input c-form-field--btn-combo">
        <input type="text" name="text" value="" class="c-input c-input--btn-combo" />

        <label class="c-btn c-btn--primary c-btn--input-combo" for="btn-search">
          <svg class="c-svg c-svg--search-24" aria-labelledby="svg-title">
            <title id="svg-title">Search</title>
            <use xlink:href="#c-svg--search-24"></use>
          </svg>
          <input id="btn-search" type="submit" value="Search" class="c-btn__input" />
        </label>
      </div>
    </div>

 */
.c-btn--input-combo {
  display: inline-block; /* [1] */
  margin: 0;    /* [1] */
  padding: 0;
  width: 40px;  /* [2] */
  height: $input-field-height; /* [3] */
  border-top-left-radius:    0;
  border-bottom-left-radius: 0;
  border-top-right-radius:    $border-radius-small;
  border-bottom-right-radius: $border-radius-small;
  vertical-align: top;
  line-height: 2.75em;
  flex-shrink: 0;
  span {
    display: none;
    @include hide-text(); /* [4] */
  }

  .c-svg {
    @include position-center();
    width:  var(--aura-c-button-icon-size, $icon-size-default);
    height: var(--aura-c-button-icon-size, $icon-size-default);
  }

  &:hover {
    .c-svg {
      color: currentColor;
    }
  }

}





/* Inverse buttons
   ========================================================================== */
/**
 * Inverse buttons are used on dark backgrounds.
 */
.c-btn--inverse {
  @include button-spacing-inline();

  &,
  &:link,
  &:visited {
    background-color: var(--aura-c-button-inverse-color-background, $button-inverse-color-background);
    border-color: var(--aura-c-button-inverse-color-border, $button-inverse-color-border);
    color: var(--aura-c-button-inverse-text-color, $button-inverse-color-text-primary);
  }

  &:hover,
  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    background-color: var(--aura-c-button-inverse-color-background-hover, $button-inverse-color-background-hover);
    border-color: var(--aura-c-button-inverse-color-border-hover, $button-inverse-color-border-hover);
    color: var(--aura-c-button-inverse-text-color-hover, $button-inverse-color-text-primary);
  }

  &:active{
    background-color: var(--aura-c-button-inverse-color-background-active, $button-inverse-color-background-active);
    border-color: var(--aura-c-button-inverse-color-border-active, $button-inverse-color-border-active);
    color: var(--aura-c-button-inverse-text-color-active, $button-inverse-color-text-primary);
  }

  &:disabled,
  &[disabled],
  &.is-disabled,
  &[disabled="disabled"] {

    @include all-anchor-states() {
      background-color: var(--aura-c-button-inverse-color-background, $button-inverse-color-background);
      border-color: var(--aura-c-button-inverse-color-border, $button-inverse-color-border);
      color: var(--aura-c-button-inverse-text-color, $button-inverse-color-text-primary);
    }

  }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-inverse-shadow-focus, $button-inverse-shadow);
  }

}


.c-btn--outline-inverse {
  @include button-spacing-inline();
  &,
  &:link,
  &:visited {
    background-color: var(--aura-c-button-outline-inverse-color-background, transparent);
    border-color: var(--aura-c-button-outline-inverse-color-border, $button-inverse-color-border);
    color: var(--aura-c-button-outline-inverse-text-color, $button-inverse-color-text);
  }

  &:hover,
  &:active,
  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    background-color: var(--aura-c-button-outline-inverse-color-background-hover, transparent);
    border-color: var(--aura-c-button-outline-inverse-color-border-hover, $button-inverse-color-border-hover);
    color: var(--aura-c-button-outline-inverse-text-color-hover, $button-inverse-color-text);
  }

  &:active {
    background-color: var(--aura-c-button-outline-inverse-color-background-active, transparent);
    border-color: var(--aura-c-button-outline-inverse-color-border-active, $button-inverse-color-border-active);
    color: var(--aura-c-button-outline-inverse-text-color-active, $button-inverse-color-text);
  }

  &:disabled,
  &[disabled],
  &.is-disabled,
  &[disabled="disabled"] {

    @include all-anchor-states() {
      background-color: var(--aura-c-button-outline-inverse-color-background, transparent);
      border-color: var(--aura-c-button-outline-inverse-color-border, $button-inverse-color-border);
      color: var(--aura-c-button-outline-inverse-text-color, $button-inverse-color-text);
    }

  }

  &:hover {
    box-shadow: var(--aura-c-button-outline-inverse-shadow-hover, $button-outline-inverse-inset-shadow-hover);
  }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-outline-inverse-shadow-focus, $button-inverse-shadow);
  }


}



.c-btn--text-inverse {

  &,
  &:link,
  &:visited {
    color: var(--aura-c-button-text-inverse-text-color, $button-inverse-color-text);
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--aura-c-button-text-inverse-text-color-hover, rgba($button-inverse-color-text, $opacity-8));
  }

  &:disabled,
  &.is-disabled,
  &[disabled="disabled"] {

    @include all-anchor-states() {
      color: var(--aura-c-button-text-inverse-text-color, $button-inverse-color-text);
    }

  }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: var(--aura-c-button-inverse-shadow-focus, $button-inverse-shadow);
  }

}




/* Buttons with an icon
   ========================================================================== */
/**
 * 1. Reset pointer-events on submit button.
 * 2. Icons are placed on the right by default, we need to add extra padding to
 *    account for the positioning of the icon.
 * 3. We have to do some negative margin trickery so our input button looks
 *    and interacts like any other button.
 * 4. Then re-add the padding to the input element.
 *
 * HTML

  Anchor:
    <a href="#0" id="btnLinkIcon" class="c-btn c-btn--primary has-icon" role="button">
      <svg class="c-svg c-svg--arrow-right-24" aria-hidden="true" focusable="false">
        <use xlink:href="#c-svg--arrow-right-24"></use>
      </svg>
      Link
    </a>

  Button:
    <button id="btnButtonIcon" class="c-btn c-btn--secondary has-icon">
      <svg class="c-svg c-svg--add-24" aria-hidden="true" focusable="false">
        <use xlink:href="#c-svg--add-24"></use>
      </svg>
      Button
    </button>

  Input:
    <label class="c-btn c-btn--primary has-icon is-input-btn" for="btnSubmitIcon">
      <svg class="c-svg c-svg--add-24" aria-hidden="true" focusable="false">
        <use xlink:href="#c-svg--add-24"></use>
      </svg>
      Submit
      <input id="btnSubmitIcon" type="submit" value="Submit" class="c-btn__input" />
    </label>

 *
 */
.c-btn {

  &:not(.c-btn--text) {

    // Icons sould be to the right of the text by default,
    // but sometimes you might need it on the left.
    &.has-icon,
    &.has-icon-left {

      .c-svg {
        @include position-y-center();
        width:  var(--aura-c-button-icon-size, $icon-size-default);
        height: var(--aura-c-button-icon-size, $icon-size-default);
        color: currentColor;
        background-color: transparent;
      }

    }

    &.has-icon {
      padding-right: var(--aura-c-button-padding-inline-icon, $spacing-x-large - $spacing-tiny); /* [2] */

      .c-svg {
        left: auto;
        right: var(--aura-c-button-padding-inline-icon-start, $spacing-medium); /* [2] */
      }

    }

    &.has-icon-left {
      padding-left: var(--aura-c-button-padding-inline-icon, $spacing-x-large - $spacing-tiny); /* [2] */

      .c-svg {
        right: auto;
        left: var(--aura-c-button-padding-inline-icon-start, $spacing-medium); /* [2] */
      }

    }

  }

  &.c-btn--text {

    &:not(.is-back-btn, .has-icon-bubble) {
      padding: 0;

      &.has-icon,
      &.has-icon-left {
        .c-svg {
          top: 0;
          width:  var(--aura-c-button-icon-size, $icon-size-default);
          height: var(--aura-c-button-icon-size, $icon-size-default);
        }
      }

      &.has-icon {
        .c-svg {
          margin-left: $spacing-tiny;
        }
      }

      &.has-icon-left {
        .c-svg {
          margin-right: $spacing-tiny;
        }
      }

    }

    &.is-back-btn {
      padding: 0;
      padding-left: $spacing-default;

      .c-svg {
        position: absolute;
        top: 50%;
        left: 0;
        width:  $icon-size-small;
        height: $icon-size-small;
        color: currentColor;
        @include transform(translateX(0) translateY(-50%));
        transition: 0.3s transform ease-out;
      }

      &:hover {
        .c-svg {
          @include transform(translateX(-3px) translateY(-50%));
        }
      }

    }

  }

}




/* Size variants
   ========================================================================== */
.c-btn--auto {
  width: auto;
  min-width: unset;
  max-width: unset;
}

.c-btn--small {
  height: var(--aura-c-button-height-small, $c-button-height-small);
  @include font-size($font-size-caption, $c-button-height-small);
  font-weight: $font-weight-caption;
}

// .c-btn--large {
//   padding-left: $spacing-large;
//   padding-right: $spacing-large;
// }



// We might want certain buttons to be of the same width, or minimum width,
// such as form submit buttons that might have text of varying length, like:
// "Save", "Submit", "Done", "Ok", you get the idea.
.c-btn--min-width {

  @include mq($from: tablet) {
    min-width: var(--aura-c-button-min-width);
    min-width: var(--aura-c-button-min-width, $c-button-min-width);
  }

}

.c-btn--short {

  @include mq($from: tablet) {
    width: var(--aura-c-button-short-width, $c-button-width-short);
  }

}

.c-btn--wide {

  @include mq($from: tablet) {
    width: var(--aura-c-button-wide-width, $c-button-width-wide);
  }

}

// Make the button be the full width of it's parent container.
.c-btn--full {
  &,
  .c-btn__input {
    width: 100%;
    max-width: 100%;
  }
}

.c-btn--tight {
  padding-left:  var(--aura-c-button-tight-padding-inline-start, $spacing-default);
  padding-right: var(--aura-c-button-tight-padding-inline-end, $spacing-default);
}

// .c-btn--tight,
// .c-btn--small,
// .c-btn--large {

//   &.has-icon {
//     padding-left: --aura-c-button-padding-icon;
//   }

//   &.has-icon-left {
//     padding-right: --aura-c-button-padding-icon;
//   }

// }
