/* Sizing
   ========================================================================== */
$header-min-height-patient:     80px !default;
$header-min-height-provider:    70px !default;

$header-min-height:             80px !default;
$input-field-height:            38px !default;

$icon-size-small:               16px !default;
$icon-size-default:             24px !default;
$icon-size-large:               32px !default;
$round-icon-boundary:           40px !default;

$select-arrow-icon-sizing:      $icon-size-small !default;

$nubbin-size-small:             3px;
$nubbin-size-large:             16px;

$popover-nubbin-size:           $nubbin-size-large !default; // Size of the pointer arrow
$tooltip-nubbin-size:           $nubbin-size-small !default;

// Border sizing
$border-width-thin:             1px !default;
$border-width-thick:            2px !default;
$border-width-x-thick:          4px !default;

$badge-border-width:            $border-width-thin !default;

$button-border-width:           $border-width-thin !default;
$button-height:                 $input-field-height - ($button-border-width*2) !default;

$page-head-border-width:        $border-width-thin !default;
$page-footer-border-width:      $border-width-thin !default;
$form-input-border-width:       $border-width-thin !default;
$popover-border-width:          $border-width-thin !default;