/* ==========================================================================
   AURACSS
   ========================================================================== */

/**

      ___           ___           ___           ___           ___           ___           ___
     /\  \         /\  \         /\  \         /\  \         /\__\         /\__\         /\__\
    /::\  \        \:\  \       /::\  \       /::\  \       /:/  /        /:/ _/_       /:/ _/_
   /:/\:\  \        \:\  \     /:/\:\__\     /:/\:\  \     /:/  /        /:/ /\  \     /:/ /\  \
  /:/ /::\  \   ___  \:\  \   /:/ /:/  /    /:/ /::\  \   /:/  /  ___   /:/ /::\  \   /:/ /::\  \
 /:/_/:/\:\__\ /\  \  \:\__\ /:/_/:/__/___ /:/_/:/\:\__\ /:/__/  /\__\ /:/_/:/\:\__\ /:/_/:/\:\__\
 \:\/:/  \/__/ \:\  \ /:/  / \:\/:::::/  / \:\/:/  \/__/ \:\  \ /:/  / \:\/:/ /:/  / \:\/:/ /:/  /
  \::/__/       \:\  /:/  /   \::/~~/~~~~   \::/__/       \:\  /:/  /   \::/ /:/  /   \::/ /:/  /
   \:\  \        \:\/:/  /     \:\~~\        \:\  \        \:\/:/  /     \/_/:/  /     \/_/:/  /
    \:\__\        \::/  /       \:\__\        \:\__\        \::/  /        /:/  /        /:/  /
     \/__/         \/__/         \/__/         \/__/         \/__/         \/__/         \/__/

 * AURACSS v1.1.0-beta
 * see CHANGELOG.md for details.
 * By: jason.otis@resmed.com
 */


/****************************************************************************
 * INSTRUCTIONS
 *
 *
 * NPM Dependencies
 *   AURACSS is dependent on inuitcss and sassmq. The inuitcss framework and
 *   sassmq are included here manually in `/sass/modules/`. These CSS modules
 *   are available as packages. If you are using NPM, please DO NOT include and
 *   replace our included versions of these packages with the NPM versions.
 *
 *
 * inuitcss, by @csswizardry
 *   https://github.com/inuitcss/inuitcss
 *
 * SASS-MQ
 *   https://github.com/sass-mq/sass-mq
 *
 ******************************************************************************/


/******************************************************************************
 * SASS commands [http://sass-lang.com/documentation/file.SASS_REFERENCE.html]
 *
 *      !!!!!!   WATCH FOLDER AND COMPILE WITH THIS COMMAND   !!!!!!
 *
 * In a terminal window, cd to the parent of your SASS folder and type this command:
 *
 *  >>> path/to/sass-folder-parent/$  sass --watch sass:css --style compressed
 *
 * Note:
 *  You may need to change the `output` folder path depending on your project structure.
 * For example:
 *   sass --watch sass:assets/css --style compressed
 *
 ******************************************************************************/

/******************************************************************************

                        █░█░█ ▄▀█ █▀█ █▄░█ █ █▄░█ █▀▀ █
                        ▀▄▀▄▀ █▀█ █▀▄ █░▀█ █ █░▀█ █▄█ ▄

      !!!!!!   Most partial SASS files are imported by default   !!!!!!

  Comment out any SASS partials that you do not need for your project to help
  keep your outputted CSS file size down. There's no meed to include classes
  you won't be using.

 ******************************************************************************/


/**
 * CONTENTS
 *
 * FONTS
 * Cern.................Currently the corporate web font.
 *
 * CORE
 * Color-palettes.......ResMed branded color palettes system.
 *
 * THEMES
 * Patient..............Patient facing default theme config.
 *                      Currently matches !default settings when no theme is used.
 * Provider.............Provider facing default theme config.
 * Custom theme.........Configure a theme for your UI.
 *
 * SETTINGS
 * Config...............Project-level configuration and feature switches.
 * Typography...........Project-wide typography variables and settings.
 * Core.................AURACSS core and setup settings to overwrite inuitcss defaults.
 * Core.................inuitcss’ core and setup settings.
 * Breakpoints..........Breakpoint settings for media queries.
 * Colors...............Project-wide color variables and settings.
 * Global...............Project-wide variables and settings.
 * Utilities............Config dynamically generated utility classes.
 *
 * TOOLS
 * Font-size............A mixin which guarantees baseline-friendly line-heights.
 * Clearfix.............Micro clearfix mixin.
 * Hidden...............Mixin for hiding elements.
 * Aliases..............Aliases to inuitcss mixins for easier typing.
 * Animation............Animation mixins that provide prefixing.
 * Color variations.....A function to mix colors on the fly.
 * Color functions......Functions to lighten or darken colors.
 * Compass..............Mixins from the Compass framework that we still use.
 * Postioning...........Mixins for positioning of elements.
 * Properties...........CSS properties, mostly for prefixing.
 * SVG..................SVG related mixins.
 * Transforms...........Prefixer mixins for CSS transforms.
 *
 * MEDIA QUERIES
 * Sass MQ..............inuitcss’ default media query manager.
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 * Reset................A tiny reset to complement Normalize.css.
 * Shared...............Sensibly and tersely share some global commonalities
 *                      (particularly useful when managing vertical rhythm).
 *
 * ELEMENTS
 * Page.................Set up our document’s default `font-size` and
 *                      `line-height`.
 * Headings.............Very minimal (i.e. only font-size information) for
 *                      headings 1 through 6.
 * Images...............Base image styles.
 * Tables...............Simple table styles.
 * Forms................Form input defaults for font-family, font-weight, color.
 * Page.................Our page defaults, sticky footer, inuitcss overrides.
 * Tables...............Our table defaults, inuitcss overrides.
 * Typography...........Font-family, font-weight, and color defaults for HTML,
 *                      H, B, STRONG, LABEL, A elements.
 *
 * VENDORS
 * Slick................Responsive carousel.
 * Modaal...............Accessible modal windows.
 * Ladda................Add loading indicators to your buttons.
 *
 * OBJECTS
 * Wrapper..............Page constraint object.
 * Layout...............Generic layout module.
 * Media................Image- and text-like content side by side. The
 *                      poster-child of OOCSS.
 * Flag.................Table-layout-based advancement on the Media object.
 * List-bare............Lists with no bullets or indents.
 * List-inline..........A list whose items all site in a line.
 * Box..................Simple boxing abstraction.
 * Block................Image-on-top-of-text object.
 * Ratio................A container for maintaining aspect ratio of content.
 * Crop.................Provide a cropping context for media (images, etc.).
 * Table................Classes for manipulating `table`s.
 * Pack.................Pack items into available horizontal space.
 * Callouts.............Box element for call to action content.
 * Carousels............Layout customizations to the carousel.
 * Flexbody.............Page body wrapper for sticky footer.
 * Inline-Group.........Group multiple singular elememts inline such as tags or anchors.
 * Tables...............Our product specific table styles for grid views.
 * Wrapper..............Mods to InuitCSS wrapper.
 * Layout...............Mods to InuitCSS layout.
 * Sections.............Define your content area, break it up in to sections.
 * Scrollable...........Scrollable container.
 *
 * ANIMATIONS
 * Custom...............Some custom CSSkeyframe animations.
 *
 * COMPONENTS
 * Alerts...............Global alerts messages, errors, warnings, and success.
 * Anchors..............Anchor component, style your links.
 * Android-Badges.......Get it on Android.
 * Apple-Badges.........Apple download badges.
 * Buttons..............Button component.
 * Cards................Content lures, Title, Image, Description.
 * Code.................Show code snippets in a nice <code> block. Uses Prism.js.
 * Directional nav......Previous Next chevron nav.
 * Dropdowns............Custom dropdown.
 * Expander.............Collapsible content.
 * Footer...............Page footers.
 * Forms................Form inputs.
 * Hero.................Custom hero banners.
 * Icons................Specific inline icons.
 * Button Icons.........Icons which interact as a button.
 * Indicators...........Little status indicators.
 * Loaders..............CSS page loading spinners.
 * Logos................Branding items.
 * Modal................Simple modal window.
 * Notifications........Site notifications.
 * Page-head............Page headers, masthead, hero.
 * Pagination...........Pagination for lists of results, wizard, stepper.
 * Popover..............Fancy tooltip that stays active so you can hover over it.
 * Progress.............Progress bar.
 * Sitenav..............Navigation component.
 * Survey...............Survey modal with stepper.
 * SVG Symbols..........SVG symbols baseline CSS.
 * Tags.................Text in a box.
 * Tooltips.............CSS only style-able tooltip option.
 * Typography...........Typography related styles, headings, labels, text.
 * Video................Video player overay.
 * Wizards..............Wizard, step-through.
 *
 * UTILITIES
 * Clearfix.............Bind our clearfix onto a utility class.
 * Widths...............Simple width helper classes.
 * Headings.............Reassigning our heading styles to helper classes.
 * Spacings.............Nudge bits of the DOM around with these spacing
 *                      classes.
 * Responsive-Spacings..Enhances the function of normal spacings for
 *                      responsive usage.
 * Print................Reset-like styles taken from the HTML5 Boilerplate.
 * Hide.................Helper classes to hide content.
 * Accessibility........Accessibility related classes.
 * Border...............Border and border radius classes.
 * Color fill...........Background color classes created from color maps.
 * Float................Float or unfloat elements.
 * Percent widths.......Specific percentage widths for grid view columns.
 * Positioning..........Helper classes to position elements - float.
 * Typography...........Helper classes for specific text and font styles.
 * Visibility...........Show or hide DOM elements at/or between breakpoints.
 */



// TOOLS
@import "modules/inuitcss/tools/tools.font-size";
@import "modules/inuitcss/tools/tools.clearfix";
@import "modules/inuitcss/tools/tools.hidden";
@import "tools/tools.aliases";
@import "tools/tools.animation";
@import "tools/tools.color.functions";
@import "tools/tools.compass";
@import "tools/tools.layout";
@import "tools/tools.positioning";
@import "tools/tools.properties";
@import "tools/tools.svg";
@import "tools/tools.transforms";



// TOKENS
@import "design-tokens";


// SETTINGS
// @import "settings/settings.config";
// @import "settings/settings.fonts";
@import "settings/settings.core";
@import "settings/settings.color";
@import "settings/settings.breakpoints";
@import "settings/settings.utilities";
@import "modules/inuitcss/settings/settings.core";



// MEDIA QUERIES
@import "modules/sass-mq/mq";



// GENERIC
@import "modules/inuitcss/generic/generic.box-sizing";
@import "modules/inuitcss/generic/generic.normalize";
@import "modules/inuitcss/generic/generic.reset";
@import "modules/inuitcss/generic/generic.shared";




// ELEMENTS
@import "modules/inuitcss/elements/elements.page";
@import "modules/inuitcss/elements/elements.headings";
@import "modules/inuitcss/elements/elements.images";
@import "elements/elements.forms";
@import "elements/elements.page";
@import "elements/elements.typography";




// OBJECTS
//
// Uncomment and add to this section as necessary.
// @import "modules/inuitcss/objects/objects.wrapper";
@import "modules/inuitcss/objects/objects.layout";
// @import "modules/inuitcss/objects/objects.media";
// @import "modules/inuitcss/objects/objects.table";

@import "objects/objects.layout";



// CSS ANIMATIONS
// @import "animations/animations.custom";



// COMPONENTS
@import "components/buttons/components.buttons";
@import "components/components.stepper-nav";
@import "components/components.forms";
@import "components/components.pagination";
@import "components/components.progress";
@import "components/components.svg-symbols";

@import "custom-components/custom.survey-no-flexbox";
@import "custom-components/custom.mobile-ui-no-flexbox";




// UTILITIES
@import "modules/inuitcss/utilities/utilities.clearfix";
@import "modules/inuitcss/utilities/utilities.widths";
@import "modules/inuitcss/utilities/utilities.headings";
@import "modules/inuitcss/utilities/utilities.spacings";
@import "modules/inuitcss/utilities/utilities.responsive-spacings";
@import "modules/inuitcss/utilities/utilities.print";
@import "modules/inuitcss/utilities/utilities.hide";

@import "utilities/utilities.accessibility";
// @import "utilities/utilities.border";
// @import "utilities/utilities.color-fill";
// @import "utilities/utilities.float";
// @import "utilities/utilities.percent.widths";
// @import "utilities/utilities.positioning";
@import "utilities/utilities.typography";
// @import "utilities/utilities.visibility";
// @import "utilities/utilities.healthcheck";
// @import "utilities/utilities.debug";
