/* ========================================================================
   #FONTS
   ======================================================================== */

/* Font stacks
   ========================================================================== */
// Standard font stacks used within the framework.

// ResMed font
$font-family-cern:          "Cern", "Helvetica Neue", "Helvetica", Helvetica, Arial, Verdana, sans-serif !default;


// System fonts
$font-family-sans-serif:    "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif !default;
$font-family-serif:         Georgia, "Times New Roman", serif !default;
$font-family-ios:           -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;


// Monospace stack
$font-family-monospace:     Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace !default;


// Google fonts
$font-family-opensans:      "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !default;
$font-family-roboto:        "Roboto", "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !default;
