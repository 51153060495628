/* ========================================================================
   #COLORS
   ======================================================================== */
// Color palettes from the Aura Design System.



/* Contrast colors
   ========================================================================== */
// White and black, plus transparencies.

$color-white:     white;
$color-white-85:  rgba(white, 0.85);
$color-white-80:  rgba(white, 0.80);
$color-white-75:  rgba(white, 0.75);
$color-white-60:  rgba(white, 0.6);
$color-white-50:  rgba(white, 0.5);
$color-white-40:  rgba(white, 0.4);
$color-white-30:  rgba(white, 0.3);
$color-white-20:  rgba(white, 0.2);
$color-white-15:  rgba(white, 0.15);

$color-black:     black;
$color-black-85:  rgba(black, 0.85);
$color-black-80:  rgba(black, 0.80);
$color-black-75:  rgba(black, 0.75);
$color-black-60:  rgba(black, 0.6);
$color-black-50:  rgba(black, 0.5);
$color-black-40:  rgba(black, 0.4);
$color-black-30:  rgba(black, 0.3);
$color-black-20:  rgba(black, 0.2);
$color-black-15:  rgba(black, 0.15);


/* Color palettes
   ========================================================================== */
// How to use:
// res-color($palette, $hue) or map-get($resmed-colors, 001)
// res-contrast($palette, $hue)

// ResMed spectrum
// taken from: http://resmedlc.com/color/
$resmed-brand-001:  #1788c3 !default;
$resmed-brand-002:  #008fd5 !default;
$resmed-brand-003:  #0083ca !default;
$resmed-brand-004:  #0077c0 !default;
$resmed-brand-005:  #006db7 !default;
$resmed-brand-006:  #2062af !default;
$resmed-brand-007:  #4156a6 !default;
$resmed-brand-008:  #55489d !default;
$resmed-brand-009:  #643695 !default;
$resmed-brand-010:  #6f2c91 !default;
$resmed-brand-011:  #822a85 !default;
$resmed-brand-012:  #94287b !default;
$resmed-brand-013:  #9d2872 !default;
$resmed-brand-014:  #a52868 !default;
$resmed-brand-015:  #ae275f !default;
$resmed-brand-016:  #b72755 !default;
$resmed-brand-017:  #c02550 !default;
$resmed-brand-018:  #c9234a !default;
$resmed-brand-019:  #d12244 !default;
$resmed-brand-020:  #da1f3d !default;
$resmed-brand-021:  #e31e36 !default;
$resmed-brand-022:  #ed1b2f !default;



/* ResMed Spectrum related palettes
   ========================================================================== */
/**
 * Below palettes generated by:
 *  http://mcg.mbitson.com/
 * Using mostly the `Traditional` algorithm setting. Some palettes
 * use the `Constantin` algorithm or a mix of both to help fill out the tonal range.
 * Use your judgement for the best tonal range when creating a new color palette.
 *
 * Color names not part of the ResMed spectrum come from:
 *  https://www.colorhexa.com/
 */


// Patient default primary-palette
// generated using `Traditional`
// $resmed-brand-001 based color palette
$resmed-brand-001-palette-50:   #e7f5fc !default;
$resmed-brand-001-palette-100:  #a3d8f4 !default;
$resmed-brand-001-palette-200:  #70c3ee !default;
$resmed-brand-001-palette-300:  #31a8e7 !default;
$resmed-brand-001-palette-400:  #1a9bde !default;
$resmed-brand-001-palette-500:  #1788c3 !default;
$resmed-brand-001-palette-600:  #1475a8 !default;
$resmed-brand-001-palette-700:  #11628c !default;
$resmed-brand-001-palette-800:  #0d4f71 !default;
$resmed-brand-001-palette-900:  #0a3c55 !default;
$resmed-brand-001-palette-A100: #daf2ff !default;
$resmed-brand-001-palette-A200: #74cfff !default;
$resmed-brand-001-palette-A400: #15aaf8 !default;
$resmed-brand-001-palette-A700: #149ae0 !default;

$resmed-brand-001-contrast-50:   $color-black-85 !default;
$resmed-brand-001-contrast-100:  $color-black-85 !default;
$resmed-brand-001-contrast-200:  $color-black-85 !default;
$resmed-brand-001-contrast-300:  $color-black-85 !default;
$resmed-brand-001-contrast-400:  $color-white !default;
$resmed-brand-001-contrast-500:  $color-white !default;
$resmed-brand-001-contrast-600:  $color-white !default;
$resmed-brand-001-contrast-700:  $color-white !default;
$resmed-brand-001-contrast-800:  $color-white !default;
$resmed-brand-001-contrast-900:  $color-white !default;
$resmed-brand-001-contrast-A100: $color-black-85 !default;
$resmed-brand-001-contrast-A200: $color-black-85 !default;
$resmed-brand-001-contrast-A400: $color-black-85 !default;
$resmed-brand-001-contrast-A700: $color-white !default;



// $resmed-brand-002 based color palette
$resmed-brand-002-palette-50:   #dff5ff !default;
$resmed-brand-002-palette-100:  #93dbff !default;
$resmed-brand-002-palette-200:  #5bc9ff !default;
$resmed-brand-002-palette-300:  #13b1ff !default;
$resmed-brand-002-palette-400:  #00a4f4 !default;
$resmed-brand-002-palette-500:  #008fd5 !default;
$resmed-brand-002-palette-600:  #007ab6 !default;
$resmed-brand-002-palette-700:  #006698 !default;
$resmed-brand-002-palette-800:  #005179 !default;
$resmed-brand-002-palette-900:  #003d5b !default;
$resmed-brand-002-palette-A100: #d5f1ff !default;
$resmed-brand-002-palette-A200: #6fd0ff !default;
$resmed-brand-002-palette-A400: #09aeff !default;
$resmed-brand-002-palette-A700: #00a0ee !default;

$resmed-brand-002-contrast-50:   $color-black-85 !default;
$resmed-brand-002-contrast-100:  $color-black-85 !default;
$resmed-brand-002-contrast-200:  $color-black-85 !default;
$resmed-brand-002-contrast-300:  $color-black-85 !default;
$resmed-brand-002-contrast-400:  $color-white !default;
$resmed-brand-002-contrast-500:  $color-white !default;
$resmed-brand-002-contrast-600:  $color-white !default;
$resmed-brand-002-contrast-700:  $color-white !default;
$resmed-brand-002-contrast-800:  $color-white !default;
$resmed-brand-002-contrast-900:  $color-white !default;
$resmed-brand-002-contrast-A100: $color-black-85 !default;
$resmed-brand-002-contrast-A200: $color-black-85 !default;
$resmed-brand-002-contrast-A400: $color-black-85 !default;
$resmed-brand-002-contrast-A700: $color-white !default;


// Provider default primary-palette
// $resmed-brand-004 based color palette
$resmed-brand-004-palette-50:   #caebff !default;
$resmed-brand-004-palette-100:  #7eceff !default;
$resmed-brand-004-palette-200:  #46b8ff !default;
$resmed-brand-004-palette-300:  #009dfd !default;
$resmed-brand-004-palette-400:  #008adf !default;
$resmed-brand-004-palette-500:  #0077c0 !default;
$resmed-brand-004-palette-600:  #0064a1 !default;
$resmed-brand-004-palette-700:  #005183 !default;
$resmed-brand-004-palette-800:  #003e64 !default;
$resmed-brand-004-palette-900:  #002b46 !default;
$resmed-brand-004-palette-A100: #c0e7ff !default;
$resmed-brand-004-palette-A200: #5ac0ff !default;
$resmed-brand-004-palette-A400: #0097f3 !default;
$resmed-brand-004-palette-A700: #0087d9 !default;

$resmed-brand-004-contrast-50:   $color-black-85 !default;
$resmed-brand-004-contrast-100:  $color-black-85 !default;
$resmed-brand-004-contrast-200:  $color-black-85 !default;
$resmed-brand-004-contrast-300:  $color-white !default;
$resmed-brand-004-contrast-400:  $color-white !default;
$resmed-brand-004-contrast-500:  $color-white !default;
$resmed-brand-004-contrast-600:  $color-white !default;
$resmed-brand-004-contrast-700:  $color-white !default;
$resmed-brand-004-contrast-800:  $color-white !default;
$resmed-brand-004-contrast-900:  $color-white !default;
$resmed-brand-004-contrast-A100: $color-black-85 !default;
$resmed-brand-004-contrast-A200: $color-black-85 !default;
$resmed-brand-004-contrast-A400: $color-white !default;
$resmed-brand-004-contrast-A700: $color-white !default;


$resmed-brand-005-palette-50:   #c1e6ff !default;
$resmed-brand-005-palette-100:  #75c7ff !default;
$resmed-brand-005-palette-200:  #3db0ff !default;
$resmed-brand-005-palette-300:  #0091f4 !default;
$resmed-brand-005-palette-400:  #007fd6 !default;
$resmed-brand-005-palette-500:  #006db7 !default;
$resmed-brand-005-palette-600:  #005b98 !default;
$resmed-brand-005-palette-700:  #00497a !default;
$resmed-brand-005-palette-800:  #00365b !default;
$resmed-brand-005-palette-900:  #00243d !default;
$resmed-brand-005-palette-A100: #b7e2ff !default;
$resmed-brand-005-palette-A200: #51b9ff !default;
$resmed-brand-005-palette-A400: #008bea !default;
$resmed-brand-005-palette-A700: #007cd0 !default;

$resmed-brand-005-contrast-50:   $color-black-85 !default;
$resmed-brand-005-contrast-100:  $color-black-85 !default;
$resmed-brand-005-contrast-200:  $color-black-85 !default;
$resmed-brand-005-contrast-300:  $color-white !default;
$resmed-brand-005-contrast-400:  $color-white !default;
$resmed-brand-005-contrast-500:  $color-white !default;
$resmed-brand-005-contrast-600:  $color-white !default;
$resmed-brand-005-contrast-700:  $color-white !default;
$resmed-brand-005-contrast-800:  $color-white !default;
$resmed-brand-005-contrast-900:  $color-white !default;
$resmed-brand-005-contrast-A100: $color-black-85 !default;
$resmed-brand-005-contrast-A200: $color-black-85 !default;
$resmed-brand-005-contrast-A400: $color-white !default;
$resmed-brand-005-contrast-A700: $color-white !default;


// Purple used in default `ResMed` gradient
$resmed-brand-010-palette-50:   #eee6f2 !default;
$resmed-brand-010-palette-100:  #d4c0de !default;
$resmed-brand-010-palette-200:  #b796c8 !default;
$resmed-brand-010-palette-300:  #9a6bb2 !default;
$resmed-brand-010-palette-400:  #854ca2 !default;
$resmed-brand-010-palette-500:  #6f2c91 !default;
$resmed-brand-010-palette-600:  #672789 !default;
$resmed-brand-010-palette-700:  #5c217e !default;
$resmed-brand-010-palette-800:  #521b74 !default;
$resmed-brand-010-palette-900:  #401062 !default;
$resmed-brand-010-palette-A100: #d098ff !default;
$resmed-brand-010-palette-A200: #b965ff !default;
$resmed-brand-010-palette-A400: #a232ff !default;
$resmed-brand-010-palette-A700: #9719ff !default;

$resmed-brand-010-contrast-50:   $color-black-85 !default;
$resmed-brand-010-contrast-100:  $color-black-85 !default;
$resmed-brand-010-contrast-200:  $color-black-85 !default;
$resmed-brand-010-contrast-300:  $color-black-85 !default;
$resmed-brand-010-contrast-400:  $color-white !default;
$resmed-brand-010-contrast-500:  $color-white !default;
$resmed-brand-010-contrast-600:  $color-white !default;
$resmed-brand-010-contrast-700:  $color-white !default;
$resmed-brand-010-contrast-800:  $color-white !default;
$resmed-brand-010-contrast-900:  $color-white !default;
$resmed-brand-010-contrast-A100: $color-black-85 !default;
$resmed-brand-010-contrast-A200: $color-black-85 !default;
$resmed-brand-010-contrast-A400: $color-white !default;
$resmed-brand-010-contrast-A700: $color-white !default;


// Danger/Error red
$resmed-brand-020-palette-50:   #fbe4e8;
$resmed-brand-020-palette-100:  #f4bcc5;
$resmed-brand-020-palette-200:  #ed8f9e;
$resmed-brand-020-palette-300:  #e56277;
$resmed-brand-020-palette-400:  #e0415a;
$resmed-brand-020-palette-500:  #da1f3d;
$resmed-brand-020-palette-600:  #d61b37;
$resmed-brand-020-palette-700:  #d0172f;
$resmed-brand-020-palette-800:  #cb1227;
$resmed-brand-020-palette-900:  #c20a1a;
$resmed-brand-020-palette-A100: #ffedee;
$resmed-brand-020-palette-A200: #ffbabe;
$resmed-brand-020-palette-A400: #ff878e;
$resmed-brand-020-palette-A700: #ff6e76;

$resmed-brand-020-contrast-50:   $color-black-85;
$resmed-brand-020-contrast-100:  $color-black-85;
$resmed-brand-020-contrast-200:  $color-black-85;
$resmed-brand-020-contrast-300:  $color-black-85;
$resmed-brand-020-contrast-400:  $color-white;
$resmed-brand-020-contrast-500:  $color-white;
$resmed-brand-020-contrast-600:  $color-white;
$resmed-brand-020-contrast-700:  $color-white;
$resmed-brand-020-contrast-800:  $color-white;
$resmed-brand-020-contrast-900:  $color-white;
$resmed-brand-020-contrast-A100: $color-black-85;
$resmed-brand-020-contrast-A200: $color-black-85;
$resmed-brand-020-contrast-A400: $color-black-85;
$resmed-brand-020-contrast-A700: $color-black-85;



$resmed-brand-022-palette-50:   #fde4e6 !default;
$resmed-brand-022-palette-100:  #fbcacf !default;
$resmed-brand-022-palette-200:  #f7969f !default;
$resmed-brand-022-palette-300:  #f15463 !default;
$resmed-brand-022-palette-400:  #ef3749 !default;
$resmed-brand-022-palette-500:  #ed1b2f !default;
$resmed-brand-022-palette-600:  #d81124 !default;
$resmed-brand-022-palette-700:  #bc0f1f !default;
$resmed-brand-022-palette-800:  #a00d1b !default;
$resmed-brand-022-palette-900:  #830a16 !default;
$resmed-brand-022-palette-A100: #ffffff !default;
$resmed-brand-022-palette-A200: #ffd6d7 !default;
$resmed-brand-022-palette-A400: #ffa3a6 !default;
$resmed-brand-022-palette-A700: #ff8a8d !default;

$resmed-brand-022-contrast-50:   $color-black-85 !default;
$resmed-brand-022-contrast-100:  $color-black-85 !default;
$resmed-brand-022-contrast-200:  $color-black-85 !default;
$resmed-brand-022-contrast-300:  $color-black-85 !default;
$resmed-brand-022-contrast-400:  $color-white !default;
$resmed-brand-022-contrast-500:  $color-white !default;
$resmed-brand-022-contrast-600:  $color-white !default;
$resmed-brand-022-contrast-700:  $color-white !default;
$resmed-brand-022-contrast-800:  $color-white !default;
$resmed-brand-022-contrast-900:  $color-white !default;
$resmed-brand-022-contrast-A100: $color-black-85 !default;
$resmed-brand-022-contrast-A200: $color-black-85 !default;
$resmed-brand-022-contrast-A400: $color-black-85 !default;
$resmed-brand-022-contrast-A700: $color-black-85 !default;


// Grays
$resmed-brand-gray-palette-50:   #f6f6f6 !default;
$resmed-brand-gray-palette-100:  #f2f2f2 !default;
$resmed-brand-gray-palette-200:  #e6e6e7 !default;
$resmed-brand-gray-palette-300:  #d9d8d5 !default;
$resmed-brand-gray-palette-400:  #b7b7b8 !default;
$resmed-brand-gray-palette-500:  #9b9b9e !default;
$resmed-brand-gray-palette-600:  #787878 !default;
$resmed-brand-gray-palette-700:  #6d6d6d !default;
$resmed-brand-gray-palette-800:  #424243 !default;
$resmed-brand-gray-palette-900:  #212121 !default;
$resmed-brand-gray-palette-A100: #ffffff !default;
$resmed-brand-gray-palette-A200: #e2e2f0 !default;
$resmed-brand-gray-palette-A400: #aaaac2 !default;
$resmed-brand-gray-palette-A700: #a4a4af !default;

$resmed-brand-gray-contrast-50:   $color-black-85 !default;
$resmed-brand-gray-contrast-100:  $color-black-85 !default;
$resmed-brand-gray-contrast-200:  $color-black-85 !default;
$resmed-brand-gray-contrast-300:  $color-black-85 !default;
$resmed-brand-gray-contrast-400:  $color-black-85 !default;
$resmed-brand-gray-contrast-500:  $color-black-85 !default;
$resmed-brand-gray-contrast-600:  $color-white !default;
$resmed-brand-gray-contrast-700:  $color-white !default;
$resmed-brand-gray-contrast-800:  $color-white !default;
$resmed-brand-gray-contrast-900:  $color-white !default;
$resmed-brand-gray-contrast-A100: $color-black-85 !default;
$resmed-brand-gray-contrast-A200: $color-black-85 !default;
$resmed-brand-gray-contrast-A400: $color-black-85 !default;
$resmed-brand-gray-contrast-A700: $color-black-85 !default;





/* Non-ResMed-Spectrum color palettes
   ========================================================================== */
/**
 * Color names not part of the ResMed spectrum come from:
 *  https://www.colorhexa.com/
 */

// generated using `Constantin`
// with a mix of `Traditional` to fill out the
// darker end of the tonal range a little better.
$palette-light-blue-50:   #e1f5fe !default;
$palette-light-blue-100:  #b3e5fc !default;
$palette-light-blue-200:  #81d4fa !default;
$palette-light-blue-300:  #4fc3f7 !default;
$palette-light-blue-400:  #29b6f6 !default;
$palette-light-blue-500:  #03a9f4 !default;
$palette-light-blue-600:  #0394d6 !default;
$palette-light-blue-700:  #027fb8 !default;
$palette-light-blue-800:  #026a99 !default;
$palette-light-blue-900:  #02557b !default;
$palette-light-blue-A100: #f7fdff !default;
$palette-light-blue-A200: #91ddff !default;
$palette-light-blue-A400: #2bbdff !default;
$palette-light-blue-A700: #11b5ff !default;

$palette-light-blue-contrast-50:  $color-black-85 !default;
$palette-light-blue-contrast-100: $color-black-85 !default;
$palette-light-blue-contrast-200: $color-black-85 !default;
$palette-light-blue-contrast-300: $color-black-85 !default;
$palette-light-blue-contrast-400: $color-black-85 !default;
$palette-light-blue-contrast-500: $color-white !default;
$palette-light-blue-contrast-600: $color-white !default;
$palette-light-blue-contrast-700: $color-white !default;
$palette-light-blue-contrast-800: $color-white !default;
$palette-light-blue-contrast-900: $color-white !default;
$palette-light-blue-contrast-A100: $color-black-85 !default;
$palette-light-blue-contrast-A200: $color-black-85 !default;
$palette-light-blue-contrast-A400: $color-black-85 !default;
$palette-light-blue-contrast-A700: $color-black-85 !default;


// Used for selection bgcolor highlight
// generated using `Constantin`
$palette-light-yellow-50:   #fffbeb !default;
$palette-light-yellow-100:  #fff5cc !default;
$palette-light-yellow-200:  #ffefaa !default;
$palette-light-yellow-300:  #ffe888 !default;
$palette-light-yellow-400:  #ffe36f !default;
$palette-light-yellow-500:  #ffde55 !default;
$palette-light-yellow-600:  #ffda4e !default;
$palette-light-yellow-700:  #ffd544 !default;
$palette-light-yellow-800:  #ffd13b !default;
$palette-light-yellow-900:  #ffc82a !default;
$palette-light-yellow-A100: #ffffff !default;
$palette-light-yellow-A200: #fffcee !default;
$palette-light-yellow-A400: #fff4d7 !default;
$palette-light-yellow-A700: #ffedbe !default;

$palette-light-yellow-contrast-50:   $color-black-85 !default;
$palette-light-yellow-contrast-100:  $color-black-85 !default;
$palette-light-yellow-contrast-200:  $color-black-85 !default;
$palette-light-yellow-contrast-300:  $color-black-85 !default;
$palette-light-yellow-contrast-400:  $color-black-85 !default;
$palette-light-yellow-contrast-500:  $color-black-85 !default;
$palette-light-yellow-contrast-600:  $color-black-85 !default;
$palette-light-yellow-contrast-700:  $color-black-85 !default;
$palette-light-yellow-contrast-800:  $color-black-85 !default;
$palette-light-yellow-contrast-900:  $color-black-85 !default;
$palette-light-yellow-contrast-A100: $color-black-85 !default;
$palette-light-yellow-contrast-A200: $color-black-85 !default;
$palette-light-yellow-contrast-A400: $color-black-85 !default;
$palette-light-yellow-contrast-A700: $color-black-85 !default;


// Used with warning messages and badges
// generated using `Constantin`
$palette-light-orange-50:   #fff8e9 !default;
$palette-light-orange-100:  #ffedc7 !default;
$palette-light-orange-200:  #ffe1a2 !default;
$palette-light-orange-300:  #ffd57c !default;
$palette-light-orange-400:  #ffcc60 !default;
$palette-light-orange-500:  #ffc344 !default;
$palette-light-orange-600:  #ffbd3e !default;
$palette-light-orange-700:  #ffb535 !default;
$palette-light-orange-800:  #ffae2d !default;
$palette-light-orange-900:  #ffa11f !default;
$palette-light-orange-A100: #ffffff !default;
$palette-light-orange-A200: #fff4dd !default;
$palette-light-orange-A400: #ffe9ce !default;
$palette-light-orange-A700: #ffdeb5 !default;

$palette-light-orange-contrast-50:   $color-black-85 !default;
$palette-light-orange-contrast-100:  $color-black-85 !default;
$palette-light-orange-contrast-200:  $color-black-85 !default;
$palette-light-orange-contrast-300:  $color-black-85 !default;
$palette-light-orange-contrast-400:  $color-black-85 !default;
$palette-light-orange-contrast-500:  $color-black-85 !default;
$palette-light-orange-contrast-600:  $color-black-85 !default;
$palette-light-orange-contrast-700:  $color-black-85 !default;
$palette-light-orange-contrast-800:  $color-black-85 !default;
$palette-light-orange-contrast-900:  $color-black-85 !default;
$palette-light-orange-contrast-A100: $color-black-85 !default;
$palette-light-orange-contrast-A200: $color-black-85 !default;
$palette-light-orange-contrast-A400: $color-black-85 !default;
$palette-light-orange-contrast-A700: $color-black-85 !default;


// Used with warning badges
// generated using `Constantin`
$palette-vivid-orange-50:   #fbe9e2 !default;
$palette-vivid-orange-100:  #f4c8b6 !default;
$palette-vivid-orange-200:  #eda485 !default;
$palette-vivid-orange-300:  #e68054 !default;
$palette-vivid-orange-400:  #e0642f !default;
$palette-vivid-orange-500:  #db490a !default;
$palette-vivid-orange-600:  #d74209 !default;
$palette-vivid-orange-700:  #d23907 !default;
$palette-vivid-orange-800:  #cd3105 !default;
$palette-vivid-orange-900:  #c42103 !default;
$palette-vivid-orange-A100: #ffeeec !default;
$palette-vivid-orange-A200: #ffc1b9 !default;
$palette-vivid-orange-A400: #ff9386 !default;
$palette-vivid-orange-A700: #ff7c6d !default;

$palette-vivid-orange-contrast-50:   $color-black-85 !default;
$palette-vivid-orange-contrast-100:  $color-black-85 !default;
$palette-vivid-orange-contrast-200:  $color-black-85 !default;
$palette-vivid-orange-contrast-300:  $color-black-85 !default;
$palette-vivid-orange-contrast-400:  $color-black-85 !default;
$palette-vivid-orange-contrast-500:  $color-white !default;
$palette-vivid-orange-contrast-600:  $color-white !default;
$palette-vivid-orange-contrast-700:  $color-white !default;
$palette-vivid-orange-contrast-800:  $color-white !default;
$palette-vivid-orange-contrast-900:  $color-white !default;
$palette-vivid-orange-contrast-A100: $color-black-85 !default;
$palette-vivid-orange-contrast-A200: $color-black-85 !default;
$palette-vivid-orange-contrast-A400: $color-black-85 !default;
$palette-vivid-orange-contrast-A700: $color-black-85 !default;



// Used with success messages and badges
// generated using `Constantin`
$palette-mod-green-50:   #edf6ea !default;
$palette-mod-green-100:  #d3e9cc !default;
$palette-mod-green-200:  #b6daaa !default;
$palette-mod-green-300:  #98cb87 !default;
$palette-mod-green-400:  #82bf6e !default;
$palette-mod-green-500:  #6cb454 !default;
$palette-mod-green-600:  #64ad4d !default;
$palette-mod-green-700:  #59a443 !default;
$palette-mod-green-800:  #4f9c3a !default;
$palette-mod-green-900:  #3d8c29 !default;
$palette-mod-green-A100: #d8ffcf !default;
$palette-mod-green-A200: #aeff9c !default;
$palette-mod-green-A400: #84ff69 !default;
$palette-mod-green-A700: #6fff50 !default;

$palette-mod-green-contrast-50:   $color-black-85 !default;
$palette-mod-green-contrast-100:  $color-black-85 !default;
$palette-mod-green-contrast-200:  $color-black-85 !default;
$palette-mod-green-contrast-300:  $color-black-85 !default;
$palette-mod-green-contrast-400:  $color-black-85 !default;
$palette-mod-green-contrast-500:  $color-black-85 !default;
$palette-mod-green-contrast-600:  $color-black-85 !default;
$palette-mod-green-contrast-700:  $color-black-85 !default;
$palette-mod-green-contrast-800:  $color-white !default;
$palette-mod-green-contrast-900:  $color-white !default;
$palette-mod-green-contrast-A100: $color-black-85 !default;
$palette-mod-green-contrast-A200: $color-black-85 !default;
$palette-mod-green-contrast-A400: $color-black-85 !default;
$palette-mod-green-contrast-A700: $color-black-85 !default;


// generated using `Constantin`
// Used with badges
$palette-dark-lime-50:   #e1ede0 !default;
$palette-dark-lime-100:  #b3d1b3 !default;
$palette-dark-lime-200:  #81b380 !default;
$palette-dark-lime-300:  #4e954d !default;
$palette-dark-lime-400:  #287e26 !default;
$palette-dark-lime-500:  #026700 !default;
$palette-dark-lime-600:  #025f00 !default;
$palette-dark-lime-700:  #015400 !default;
$palette-dark-lime-800:  #014a00 !default;
$palette-dark-lime-900:  #013900 !default;
$palette-dark-lime-A100: #6fff6f !default;
$palette-dark-lime-A200: #3cff3c !default;
$palette-dark-lime-A400: #09ff09 !default;
$palette-dark-lime-A700: #00ef00 !default;

$palette-dark-lime-contrast-50:  $color-black-85 !default;
$palette-dark-lime-contrast-100: $color-black-85 !default;
$palette-dark-lime-contrast-200: $color-black-85 !default;
$palette-dark-lime-contrast-300: $color-white !default;
$palette-dark-lime-contrast-400: $color-white !default;
$palette-dark-lime-contrast-500: $color-white !default;
$palette-dark-lime-contrast-600: $color-white !default;
$palette-dark-lime-contrast-700: $color-white !default;
$palette-dark-lime-contrast-800: $color-white !default;
$palette-dark-lime-contrast-900: $color-white !default;
$palette-dark-lime-contrast-A100: $color-black-85 !default;
$palette-dark-lime-contrast-A200: $color-black-85 !default;
$palette-dark-lime-contrast-A400: $color-black-85 !default;
$palette-dark-lime-contrast-A700: $color-black-85 !default;



// generated using `Traditional`
// Used with tooltip, popovers, and Dark UI (in progress)
$palette-blue-gray-50:   #9dacc2 !default;
$palette-blue-gray-100:  #6e84a5 !default;
$palette-blue-gray-200:  #546887 !default;
$palette-blue-gray-300:  #38465b !default;
$palette-blue-gray-400:  #2d3848 !default;
$palette-blue-gray-500:  #212935 !default;
$palette-blue-gray-600:  #151a22 !default;
$palette-blue-gray-700:  #0a0c0f !default;
$palette-blue-gray-800:  #0b0f16 !default;
$palette-blue-gray-900:  #000000 !default;
$palette-blue-gray-A100: #7da1d7 !default;
$palette-blue-gray-A200: #386bb7 !default;
$palette-blue-gray-A400: #2a3f5f !default;
$palette-blue-gray-A700: #283547 !default;

$palette-blue-gray-contrast-50:   $color-black-85 !default;
$palette-blue-gray-contrast-100:  $color-black-85 !default;
$palette-blue-gray-contrast-200:  $color-white !default;
$palette-blue-gray-contrast-300:  $color-white !default;
$palette-blue-gray-contrast-400:  $color-white !default;
$palette-blue-gray-contrast-500:  $color-white !default;
$palette-blue-gray-contrast-600:  $color-white !default;
$palette-blue-gray-contrast-700:  $color-white !default;
$palette-blue-gray-contrast-800:  $color-white !default;
$palette-blue-gray-contrast-900:  $color-white !default;
$palette-blue-gray-contrast-A100: $color-black-85 !default;
$palette-blue-gray-contrast-A200: $color-white !default;
$palette-blue-gray-contrast-A400: $color-white !default;
$palette-blue-gray-contrast-A700: $color-white !default;


// Neutral grays
$palette-neutral-00:  #000000 !default;
$palette-neutral-10:  #181818 !default;
$palette-neutral-15:  #242424 !default;
$palette-neutral-20:  #2e2e2e !default;
$palette-neutral-30:  #444444 !default;
$palette-neutral-40:  #5c5c5c !default;
$palette-neutral-50:  #747474 !default;
$palette-neutral-60:  #939393 !default;
$palette-neutral-65:  #a0a0a0 !default;
$palette-neutral-70:  #aeaeae !default;
$palette-neutral-80:  #c9c9c9 !default;
$palette-neutral-90:  #e5e5e5 !default;
$palette-neutral-95:  #f3f3f3 !default;
$palette-neutral-100: #ffffff !default;

$palette-neutral-contrast-00:  $color-white !default;
$palette-neutral-contrast-10:  $color-white !default;
$palette-neutral-contrast-15:  $color-white !default;
$palette-neutral-contrast-20:  $color-white !default;
$palette-neutral-contrast-30:  $color-white !default;
$palette-neutral-contrast-40:  $color-white !default;
$palette-neutral-contrast-50:  $color-white !default;
$palette-neutral-contrast-60:  $color-black-85 !default;
$palette-neutral-contrast-65:  $color-black-85 !default;
$palette-neutral-contrast-70:  $color-black-85 !default;
$palette-neutral-contrast-80:  $color-black-85 !default;
$palette-neutral-contrast-90:  $color-black-85 !default;
$palette-neutral-contrast-95:  $color-black-85 !default;
$palette-neutral-contrast-100: $color-black-85 !default;