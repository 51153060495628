/* ==========================================================================
   #FORMS
   ========================================================================== */
/**
 * Form elements are already normalized by "inuitcss/generic/generic.normalize"
 * We can assign our base font settings for form inputs here.
 */

// input field placeholder text style
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::placeholder {
  font-style: normal;
  color: var(--aura-color-text-placeholder, $color-text-placeholder) !important;
}

/**
 * 1. Change the normalized font-family and font-size.
 * 2. Set our default font-weight.
 * 3. Make sure the text color is black by default.
 */

/* stylelint-disable selector-list-comma-newline-after */
input, button, select, option, textarea, select option {
  font-family: $font-family-sans-serif; /* [1] */
  font-weight: $font-weight-normal; /* [2] */
  font-size: inherit;
  // @include font-size($font-size-input-mobile); /* [1] */
  color: var(--aura-c-form-input-text-color, $color-text-default); /* [3] */

  // @include mq($from: tablet) {
  //   @include font-size($font-size-input);
  // }
}
/* stylelint-enable selector-list-comma-newline-after */


/* Change Autocomplete styles in Chrome */
input,
textarea,
select {

  &:-webkit-autofill {
    &,
    &:hover,
    &:focus {
      border-color: var(--aura-c-form-input-color-border-focus, $color-border-input-focus) !important;
      -webkit-text-fill-color: var(--aura-c-form-input-text-color, $color-text-default) !important;
      -webkit-box-shadow: 0 0 0 1000px rgba($color-background-primary, 0.12) inset !important;
      transition: background-color 0s 5000s ease-in-out;
    }

  }

  &:-webkit-autofill::first-line {
    font-family: $font-family-sans-serif !important;
    font-weight: $font-weight-normal !important;
    @include font-size($font-size-input-mobile, auto, 0, true);
    color: var(--aura-c-form-input-text-color, $color-text-default) !important;
  
    @include mq($from: tablet) {
      @include font-size($font-size-input, auto, 0, true);
    }

  }

}


/**
Keeping a list in case we need to explicitly target an input by type
for setting defaults. Styling should be done with a modifier class
in `components.forms.scss`

[type="tel"],
[type="url"],
[type="text"],
[type="date"],
[type="email"],
[type="month"],
[type="button"],
[type="submit"],
[type="search"],
[type="number"],
[type="textbox"],
[type="password"]

*/