/* ==========================================================================
   #SURVEY
   ========================================================================== */
// Custom modal for surveys.

.c-survey {
  position: relative;
  display: table;
  width:  100%;
  margin: 0;
  border-collapse: collapse;

  .is-full-height & {
    height: 100%; // to allow vertical-align: middle;
  }

}

.c-survey__row {
  display: table-row;
}

.c-survey__top {
  position: relative;
  display: table-cell;
  vertical-align: top;

  &.valign-middle {
    vertical-align: middle;
  }
  &.valign-bottom {
    vertical-align: bottom;
  }
}

.c-survey__bottom {
  display: table-cell;
  vertical-align: top;
  padding-top: $spacing-small;

  @include mq($from: $bp-414) {
    padding-top: $spacing-default;
  }
}


.c-survey__section {
  width: 100%;

  &.survey-choices {
    display: block;
    padding-top: $spacing-small;
  }
}

// Survey question heading
.c-survey__question {
  margin-bottom: $spacing-small;
  @include font-size(16px, 1.25);
  font-weight: $font-weight-medium;
  color: $color-text-default;

  @include mq($from: $bp-375) {
    @include font-size(18px, 1.25);
  }

  @include mq($from: $bp-414) {
    @include font-size(20px, 1.25);
  }

}


.c-survey__help {
  margin-bottom: 0;
  @include font-size(12px);
  font-weight: $font-weight-normal;
  color: $color-text-secondary;

  @include mq($from: $bp-375) {
    @include font-size(14px);
  }
  @include mq($from: $bp-414) {
    @include font-size(16px);
  }
}


.c-survey__success-msg {
  position: relative;
  padding-top: 80px;
  @include set-svg-bg-css-var("c-success", $svg-alert-success-fill, $color-text-success);

  &::before {
    @include position-x-center(24px);
    width:  48px;
    height: 48px;
    @include svg-css-var-bg-img("c-success");
  }

  h4 {
    @include font-size(16px);
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

}


// Survey Answer buttons which the user selects from
.c-survey__answer {
  position: relative;
  width: 100%;
  min-height: 60px;
  margin-bottom: $spacing-small;
  padding: $spacing-tiny $spacing-medium;
  @include font-size($font-size-body, 1.12);
  font-weight: $font-weight-normal;
  color: $color-text-secondary;
  border-width: $border-width-thin;
  border-style: solid;
  border-color:  $color-text-secondary;
  border-radius: $dialog-border-radius;
  background-color: $color-white;

  &.is-sleepyface {
    padding-left: $spacing-small;
  }

  &.is-active,
  &[data-state="is-active"] {
    @include set-svg-bg-css-var("c-checked", $svg-field-success, $color-text-primary);

    color: $color-text-primary;
    border-color: $color-border-primary;
    box-shadow: 0 0 0 1px $color-border-primary inset;

    &:not(.is-sleepyface) {
      &::before {
        @include position-y-center();
        left:   $spacing-tiny;
        width:  $icon-size-default;
        height: $icon-size-default;
        @include svg-css-var-bg-img("c-checked");
      }
    }
  }

}

.c-sleep-icon {
  height: 48px;

  &.is-selected-sleepiness {
    margin: 0 auto;
    width:  108px;
    height: 108px;
  }

  img {
    top: 0;
    width:  100%;
    height: 100%;
  }

  @include mq($from: $bp-414) {
    height: 54px;

    &.is-selected-sleepiness {
      width:  144px;
      height: 144px;
    }
  }

}

.c-sleepiness-choice {
  margin-bottom: $spacing-small;
  @include font-size($font-size-body);
  font-weight: $font-weight-medium;
  color: $color-text-default;

  @include mq($from: $bp-375) {
    margin-bottom: $spacing-default;
  }
  @include mq($from: $bp-414) {
    margin-bottom: $spacing-large;
  }
}

.c-sleep-label {
  display: block;
  @include font-size(10px, 1.15);
  font-weight: $font-weight-medium;

  @include mq($from: $bp-375) {
    @include font-size(11px, 1.25);
  }
  @include mq($from: $bp-414) {
    @include font-size(12px, 1.35);
  }

}

.c-survey__error {
  color: $color-text-danger;
}



/* Survey progress stepper
   ========================================================================== */
.c-survey__stepper {
  position: relative;
  display: block;
  margin: 0;
  margin-bottom: $spacing-large;
  margin-left: -$spacing-small;
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 0;
}

.c-survey__step-indicator {
  box-sizing: border-box;
  display: inline-block;
  width: 32px;
  height: $spacing-tiny;
  margin: 0;
  margin-left: $spacing-micro;
  padding: 0;
  background-color: $color-background-dark;
  border-radius: 2em;

  font-size: $font-size-body;
  font-size: 1rem;


  &[data-state="is-active"] {
    background-color: $color-background-primary;
  }

}
