/* ==========================================================================
   #MOBILE-UI
   ========================================================================== */

.c-heading-mobile {
  margin-bottom: 0;
  @include font-size($font-size-body, 1.25);
  font-weight: $font-weight-medium;
  color: inherit;
}

.c-mobile-nav-link {
  position: relative;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  @include appearance(none, true);
  font: inherit;
  @include font-size($font-size-body, 1.25);
  font-weight: $font-weight-normal;

  @include all-anchor-states() {
    color: inherit;
  }
}

// Layout object for mobile only pages
// Fixed header and footer with scrolling content.
app-onboarding,
app-survey-display,
app-page-not-found,
.o-page-wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.o-mobile-page {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;

  padding-right: 22px;
  padding-left:  22px;
  // overflow-y: scroll;
}

.o-mobile-page__body {
  display: block;
  width: 100%;
  padding-top: $spacing-default;
  padding-bottom: $onboarding-footer-height;

  @include mq($from: $bp-375) {
    padding-top: $spacing-large;
  }

  &.is-full-height {
    height: 100%; // to allow vertical-align: middle;
  }

  &.has-header {
    padding-top: $mobile-header-height + $spacing-default; // Match header height plus buffer

    @include mq($from: $bp-375) {
      padding-top: $mobile-header-height + $spacing-large; // Match header height plus buffer
    }

    @supports not (-webkit-touch-callout: none) {
      /* CSS for other than iOS devices */
      padding-top: $android-header-height + $spacing-default; // Match header height plus buffer

      @include mq($from: $bp-375) {
        padding-top: $android-header-height + $spacing-large; // Match header height plus buffer
      }
    }
  }

  &.has-footer {
    padding-bottom: $mobile-footer-height + $spacing-default; // Match footer height plus buffer
  }

  &.is-onboarding {
    padding-bottom: $onboarding-footer-height + $spacing-default; // Match footer height plus buffer
  }

}

.o-mobile-page__header,
.o-mobile-page__footer {
  position: fixed;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  padding: 22px;
  z-index: 100;
}

.o-mobile-page__header {
  top: 0;
  bottom: auto;
  height: $mobile-header-height;
  text-align: center;
  color: $color-text-primary-contrast;
  background-color: $color-background-primary;

  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    padding: 0 22px;
    height: $android-header-height;
  }

}


.o-mobile-page__footer {
  top: auto;
  bottom: 0;
  display: block;
  height: $mobile-footer-height;
  text-align: center;
  background-color: rgba($color-background, 0.8);
  backdrop-filter: blur(2px);

  &.is-onboarding {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
    height: $onboarding-footer-height;

    .c-survey__error {
      @include font-size(14px);

      @include mq($from: $bp-375) {
        @include font-size(16px);
      }

    }

  }
}


//
app-survey-image {
  + .c-survey__bottom {
    display: none;
  }
}

.c-table {
  display: table;
  width: 100%;
  height: 100%;
}

.c-table__cell {
  display: table-cell;
  width: 100%;
  vertical-align: top;

  &.valign-middle {
    vertical-align: middle;
  }

  &.valign-bottom {
    vertical-align: bottom;

    @supports not (-webkit-touch-callout: none) {
      vertical-align: middle;
    }

  }
}


// Position the progress bar in the footer
.c-progress {
  position: absolute;
  left: 0;
  bottom: 0;
}
