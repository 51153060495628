/* ==========================================================================
   #Positioning mixins
   ========================================================================== */
// Common positioning mixins

/* Top positions
   ========================================================================== */
// Top left
@mixin position-top-left($top: 0, $left: 0) {
  position: absolute;
  top:  $top;
  left: $left;
}


// Top right
@mixin position-top-right($top: 0, $right: 0) {
  position: absolute;
  top:   $top;
  right: $right;
  left: auto;
}



/* Middle positions
   ========================================================================== */
// Center element vertically only
@mixin position-y-center($left: 0) {
  position: absolute;
  top: 50%;
  left: $left;
  right: auto;
  @include translateY(-50%);
}

// Center element vertically on the right
@mixin position-y-center-right($right: 0) {
  position: absolute;
  top: 50%;
  left: auto;
  right: $right;
  @include translateY(-50%);
}

// Center element horizontally and vertically
@mixin position-center() {
  position: absolute;
  top:  50%;
  left: 50%;
  @include translate(-50%, -50%);
}

// Center element horizontally only
@mixin position-x-center($top: 0) {
  position: absolute;
  top: $top;
  left: 50%;
  @include translateX(-50%);
}

// Center element horizontally and vertically
@mixin position-fixed-center() {
  position: fixed;
  top:  50%;
  left: 50%;
  @include translate(-50%, -50%);
}



/* Bottom positions
   ========================================================================== */
// Bottom left
@mixin position-bottom-left($bottom: 0, $left: 0) {
  position: absolute;
  top: auto;
  right: auto;
  bottom: $bottom;
  left: $left;
}


// Bottom right
@mixin position-bottom-right($bottom: 0, $right: 0) {
  position: absolute;
  top: auto;
  right: $right;
  bottom: $bottom;
  left: auto;
}




/* Misc
   ========================================================================== */
// Prefixer mixin for sticky positioning
@mixin position-sticky($important: false) {
  @if ($important == true) {
      $important: !important;
  } @else if ($important == false) {
      $important: null;
  } @else {
      @error "`#{$important}` needs to be `true` or `false`.";
  }

  position: sticky $important;
  position: -webkit-sticky $important;
}
