/* ========================================================================
   #FONT-SETTINGS
   ======================================================================== */
/**
 * Tokens for font-family, font-sizes and font-weights as applied to text
 * selectors and UI elements.
 */

/* Font family
  ========================================================================== */
$font-family:               $font-family-opensans !default;
$font-family-heading:       $font-family-opensans !default;
$font-family-import:        true !default;



/* Font sizes
   ========================================================================== */
// Heading font sizes
$font-size-h1:              50px !default;
$font-size-h2:              36px !default;
$font-size-h3:              28px !default;
$font-size-h4:              24px !default;
$font-size-h5:              20px !default;
$font-size-h6:              17px !default;


// UI font sizes
$font-size-site-nav:        16px !default;
$font-size-sub-nav:         14px !default;
$font-size-body:            16px !default;
$font-size-button:          16px !default;
$font-size-tooltip:         14px !default;
$font-size-form-label:      16px !default;
$font-size-form-hint:       14px !default;
$font-size-form-error:      14px !default;
$font-size-small:           14px !default;
$font-size-badge:           13px !default;
$font-size-caption:         12px !default;
$font-size-footer:          14px !default;
$font-size-toast:           16px !default;
$font-size-input:           21px !default;
$font-size-input-mobile:    18px !default;
$font-size-larger:          22px !default;

$font-size-page-heading:    $font-size-h1 !default;
$font-size-card-heading:    $font-size-h5 !default;

// Line heights
$line-height-heading:       1.25 !default;
$line-height-text:          1.5 !default;



/* Font weights
   ========================================================================== */
// Use these variables with the font-weight property.
$font-weight-thin:          100 !default; // Cern Ultra Light
$font-weight-light:         300 !default; // Cern Light
$font-weight-normal:        400 !default;
$font-weight-medium:        500 !default; // Cern Medium or normal - default
$font-weight-semi-bold:     600 !default; // Nicer for Open Sans bolding
$font-weight-bold:          700 !default; // Cern Bold
$font-weight-extra:         800 !default;
$font-weight-black:         900 !default;

// Heading font weights
$font-weight-h1:            $font-weight-medium !default;
$font-weight-h2:            $font-weight-medium !default;
$font-weight-h3:            $font-weight-semi-bold !default;
$font-weight-h4:            $font-weight-semi-bold !default;
$font-weight-h5:            $font-weight-medium !default;
$font-weight-h6:            $font-weight-normal !default;

// UI font weights
$font-weight-site-nav:      $font-weight-medium !default;
$font-weight-body:          $font-weight-normal !default;
$font-weight-button:        $font-weight-bold !default;
$font-weight-tooltip:       $font-weight-medium !default;
$font-weight-form-label:    $font-weight-medium !default;
$font-weight-form-hint:     $font-weight-normal !default;
$font-weight-form-error:    $font-weight-medium !default;
$font-weight-small:         $font-weight-light !default;
$font-weight-badge:         $font-weight-bold !default;
$font-weight-caption:       $font-weight-medium !default;
$font-weight-footer:        $font-weight-medium !default;
$font-weight-toast:         $font-weight-normal !default;
$font-weight-card-heading:  $font-weight-medium !default;
$font-weight-pagination:    $font-weight-medium !default;
$font-weight-pagination-current:  $font-weight-bold !default;
