@mixin button-spacing-inline() {
  padding-left:   var(--aura-c-button-padding-inline-start-mobile, $button-padding-inline-start-mobile);
  padding-right:  var(--aura-c-button-padding-inline-end-mobile, $button-padding-inline-end-mobile);
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  border-radius: var(--aura-c-button-radius-border, $button-border-radius);

  @include mq($from: tablet) {
    padding-left:   var(--aura-c-button-padding-inline-start, $button-padding-inline-start);
    padding-right:  var(--aura-c-button-padding-inline-end, $button-padding-inline-end);
  }
}



// Hover and Focus effects for outlined buttons styles
// Outter box-shadow for Focus, Inner box-shadow for Hover and Focus.
@mixin outline-btn-hocus($hocus-inner-width: 1px, $hocus-color: $color-background-primary) {
  -webkit-box-shadow: 0 0 1px $c-btn-focus-outline-size rgba($hocus-color, 0), 
                      0 0 0 $hocus-inner-width rgba($hocus-color, 0) inset;
  box-shadow: 0 0 1px $c-btn-focus-outline-size rgba($hocus-color, 0), 
              0 0 0 $hocus-inner-width rgba($hocus-color, 0) inset;
  border-collapse: separate; /* For IE */

  &:hover {
    box-shadow: 0 0 1px $c-btn-focus-outline-size rgba($hocus-color, 0), 
                0 0 0 $hocus-inner-width rgba($hocus-color, 1) inset;
  }

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: 0 0 1px $c-btn-focus-outline-size rgba($hocus-color, $c-btn-focus-outline-opacity), 
                0 0 0 $hocus-inner-width rgba($hocus-color, 1) inset;
  }

}

// Focus effect for solid buttons styles
// Outter box-shadow for Focus.
@mixin solid-btn-hocus($hocus-color: $color-background-primary) {
  -webkit-box-shadow: 0 0 1px $c-btn-focus-outline-size rgba(transparent, 0);
  box-shadow: 0 0 1px $c-btn-focus-outline-size rgba(transparent, 0);
  border-collapse: separate; /* For IE */

  &:focus,
  &.is-input-btn:focus-within,
  &.c-btn--input-combo:focus-within {
    box-shadow: 0 0 1px $c-btn-focus-outline-size rgba($hocus-color, $c-btn-focus-outline-opacity);
  }

}


//
@function mobile-button-spacing-block() {
  @return ($c-button-font-size / 2) + (($c-button-height - $c-button-line-height-mobile - $c-button-font-size) / 2);
}