/* ==========================================================================
  #THEME - CUSTOM TOKENS
  ========================================================================== */
/**
 * Use this file to override !default token values.
 *
 * NOTE:
 *    This file must be included in the `_design-tokens.scss` file AFTER the
 *    color palettes but BEFORE all other token files are imported.
 *    See the `_design-tokens.scss` file for the commented out example.
 *
 *
 * Instructions:
 * To override !default token values.
 *
 * 1. Copy the token(s) you need to override from the corresponding token `.scss` file
      and paste it below.
 * 2. Replace the default value with your new value.
 * 3. Remove the `!default` flag after the value.
 *
 * Example:

    Step 1: copy paste variable
      $token-name: value !default;

    Step 2: change the value and remove the `!default` flag.
      $token-name: new-value;

 */


/* Theme colors
   ========================================================================== */
// Primary brand color for the theme.
// $theme-color-primary:                $resmed-brand-001-palette-500;
// $theme-color-primary-hover:          $resmed-brand-001-palette-400;
// $theme-color-primary-active:         $resmed-brand-001-palette-700;
// $theme-color-primary-lightest:       $resmed-brand-001-palette-50;
// $theme-color-primary-light:          $resmed-brand-001-palette-200;
// $theme-color-primary-dark:           $resmed-brand-001-palette-900;



/* Theme fonts
   ========================================================================== */
$font-family:                 $font-family-ios !default;
$font-family-heading:         $font-family-ios !default;

$progress-bar-border-radius:  0;
$mobile-header-height:        98px;
$android-header-height:       56px;

$mobile-footer-height:        184px;
$onboarding-footer-height:    86px;
