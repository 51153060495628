/* ==========================================================================
   #PAGINATION
   ========================================================================== */

$pagination-link-padding-inline:  $spacing-small !default;
$pagination-line-height:          $button-height !default;

//
// $pagination-color-text-results-total:         $color-text-default !default;
// $pagination-results-total-pages-font-weight:  $font-weight-bold !default;


/**
 * Pagination for results list pages
 * HTML:

    <nav class="c-pagination" aria-label="Pagination demo">
      <ul class="c-pagination__list">
        <li class="c-pagination__list-item"><a href="#0" class="c-pagination__link prev-page">Back</a></li>
        <li class="c-pagination__list-item"><a href="#0" class="c-pagination__link">1</a></li>
        <li class="c-pagination__list-item is-active"><a href="#0" class="c-pagination__link">2</a></li>
        <li class="c-pagination__list-item"><a href="#0" class="c-pagination__link">3</a></li>
        <li class="c-pagination__list-item"><a href="#0" class="c-pagination__link">4</a></li>
        <li class="c-pagination__list-item"><a href="#0" class="c-pagination__link">5</a></li>
        <li class="c-pagination__list-item"><a href="#0" class="c-pagination__link next-page">Next</a></li>
      </ul>
    </nav>

 */
.c-pagination {
  position: relative;
  display: block;
}

.c-pagination__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-pagination__list-item {
  padding: 0 var(--aura-c-pagination-list-item-spacing-inline, $spacing-tiny);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &.is-active .c-pagination__link {
    z-index: 1;
    color: var(--aura-c-pagination-text-color-current, $pagination-color-text-current);
    font-weight: var(--aura-c-pagination-font-weight-current, $font-weight-pagination-current);

    &,
    &:hover{
      &::before {
        display: none;
      }
    }
  }

  &.is-disabled .c-pagination__link {
    color: var(--aura-c-pagination-text-color-disabled, $color-text-disabled);
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
  }

}


.c-pagination__link {
  position: relative;
  display: block;
  padding-top: var(--aura-c-pagination-padding-block-start, 0);
  padding-right: var(--aura-c-pagination-padding-inline-end, $pagination-link-padding-inline);
  padding-bottom: var(--aura-c-pagination-padding-blcok-end, 0);
  padding-left: var(--aura-c-pagination-padding-inine-start, $pagination-link-padding-inline);
  font-weight: var(--aura-c-pagination-font-weight, $font-weight-pagination);
  line-height: var(--aura-c-pagination-line-height, $pagination-line-height);
  border-radius: var(--aura-c-pagination-radius-border, $pagination-radius-border);
  box-shadow: none;
  transition: $text-color-transition;

  @include all-anchor-states() {
    color: var(--aura-c-pagination-text-color, $pagination-color-text);
  }

  @include set-svg-bg-css-var("c-pagination-icon-previous", $svg-chevron-left, $color-text-link);
  @include set-svg-bg-css-var("c-pagination-icon-previous-hover", $svg-chevron-left, $color-text-link-hover);
  @include set-svg-bg-css-var("c-pagination-icon-previous-disabled", $svg-chevron-left, $pagination-color-text);
  @include set-svg-bg-css-var("c-pagination-icon-next", $svg-chevron-right, $color-text-link);
  @include set-svg-bg-css-var("c-pagination-icon-next-hover", $svg-chevron-right, $color-text-link-hover);
  @include set-svg-bg-css-var("c-pagination-icon-next-disabled", $svg-chevron-right, $pagination-color-text);

  &:hover {
    color: var(--aura-c-pagination-text-color-hover, $pagination-color-text-hover);
    text-decoration: none;

    &.prev-page:before {
      @include translate(-20%, -50%);
    }
    &.next-page:before {
      @include translate(20%, -50%);
    }

  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: var(--aura-c-pagination-box-shadow-focus, $pagination-shadow-focus);
  }

  &.prev-page,
  &.next-page {

    @include all-anchor-states() {
      color: var(--aura-color-text-link, $color-text-link);
    }

    @include hocus() {
      color: var(--aura-color-text-hover, $color-text-link-hover);
    }

    &::before {
      width:  var(--aura-c-pagination-icon-size, $icon-size-small);
      height: var(--aura-c-pagination-icon-size, $icon-size-small);
      transition-duration: $duration-promptly;
      transition-property: transform, color;
      transition-timing-function: ease-out;
    }
  }

  &.prev-page {
    padding-left: $icon-size-small + 8px;

    &::before {
      @include position-y-center();
      left: 0;
      @include svg-css-var-bg-img("c-pagination-icon-previous");
    }

    &:hover:before {
      @include svg-css-var-bg-img("c-pagination-icon-previous-hover");
    }

  }

  &.next-page {
    padding-right: $icon-size-small + 8px;

    &::before {
      @include position-y-center-right();
      @include svg-css-var-bg-img("c-pagination-icon-next");
    }

    &:hover:before {
      @include svg-css-var-bg-img("c-pagination-icon-next-hover");
    }

  }

  &:not(:disabled):not(.is-disabled) {
    cursor: pointer;
  }

  &:disabled,
  &.is-disabled {
    transition: none;

    @include all-anchor-states() {
      color: var(--aura-c-pagination-text-color, $pagination-color-text);
      cursor: default;
      background-image: none;
      border: 0;
      opacity: $opacity-disabled;

      &::before {
        transition: none;
        @include translate(0%, -50%);
      }

    }

    &.prev-page {
      &::before {
        @include svg-css-var-bg-img("c-pagination-icon-previous-disabled");
      }
    }
    &.next-page {
      &::before {
        @include svg-css-var-bg-img("c-pagination-icon-next-disabled");
      }
    }
  }

  @at-root button#{&} {
    @include all-anchor-states() {
      outline: none;
      border: 0;
      background: none;
      @include appearance(none, true);
    }
  }

  .no-chevrons & {
    &.prev-page,
    &.next-page {
      padding-right: var(--aura-c-pagination-padding-inline-end, $pagination-link-padding-inline);
      padding-left: var(--aura-c-pagination-padding-inine-start, $pagination-link-padding-inline);

      &::before {
        display: none;
      }
    }
  }

}



/**
 * Pagination that shows steps rather than page numbers.
 * current step / total steps (1 / 10)
 * HTML:

    <nav class="c-pagination c-pagination--steps" aria-label="Mask wizard page navigation">
      <ul class="c-pagination__list">
        <li class="c-pagination__list-item">
          <a href="#0" class="c-pagination__link prev-page">Back</a>
        </li>
        <li class="c-pagination__list-item">
          <div class="c-pagination__steps">
            <span class="c-pagination-current">1 <span class="u-sr-only">(currently on page 1)</span></span>
            <span class="c-pagination-divider">/</span>
            <span class="c-pagination-numpages">7</span>
          </div>
        </li>
        <li class="c-pagination__list-item">
          <a href="#0" class="c-pagination__link next-page">Next</a>
        </li>
      </ul>
    </nav>

 */
.c-pagination__steps {
  color: var(--aura-c-pagination-text-color, $pagination-color-text);
  font-weight: var(--aura-c-pagination-font-weight, $font-weight-pagination);
  line-height: var(--aura-c-pagination-line-height, $pagination-line-height);
  text-align: center;

  span {
    display: inline-block;
  }

  // &.steps-showing-results {
  //   color: $pagination-color-text-results-total;

  //   .c-pagination-current {
  //     padding: 0;
  //     font-weight: $pagination-results-total-pages-font-weight;
  //   }

  // }

}

.c-pagination-current {
  color: var(--aura-c-pagination-text-color-current, $pagination-color-text-current);
}

.c-pagination-divider {
  padding-right: var(--aura-c-pagination-divider-padding-inline-end, $pagination-link-padding-inline);
  padding-left: var(--aura-c-pagination-divider-padding-inine-start, $pagination-link-padding-inline);
}

// .c-pagination-numpages {
// }




/* Pagination variants
   ========================================================================== */
// Pagination used to show steps in a wizard, not page numbers
.c-pagination--steps {

  .c-pagination__list {
    justify-content: space-between;
  }

}




/* Pagination spacing
   ========================================================================== */
// Add spacing around pagination
.c-pagination--top {
  margin-bottom: $spacing-default;
}

.c-pagination--bottom {
  margin-top: $spacing-default;
}




/* Pagination alignment
   ========================================================================== */
// Alignment for a numbered pagination component
.c-pagination--center {
  .c-pagination__list {
    justify-content: center;
  }
}

.c-pagination--left {
  .c-pagination__list {
    justify-content: flex-start;
  }
}

.c-pagination--right {
  .c-pagination__list {
    justify-content: flex-end;
  }
}

.c-pagination--full-width {
  .c-pagination__list {
    justify-content: space-between;
  }
}
