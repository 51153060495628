/* ==========================================================================
  #LAYOUT
  ========================================================================== */

/**
 * Extending the inuitcss `o-layout` object.
 *
 * Related files:
 * `inuitcss/objects/objects.layout.scss`.
 */


/* Gutter size modifiers.
   ========================================================================== */
/**
 * Extra tiny gutter width between items. Mostly likely you'll want to use this
 * in conjunction with the `o-layout--inline-group` modifier class above.
 */
.o-layout--micro {
  margin-left: -$spacing-micro;

  > .o-layout__item {
    padding-left: $spacing-micro;
  }

}



/* Variants
   ========================================================================== */
// Make our left nav sticky when scrolling the page
.o-layout--sticky-left-nav {
  @include mq($from: tablet) {
    @include position-sticky();
    top: 146px;
  }
}


.o-layout--auto\@tablet {

  > .o-layout__item {
    width: 100%;

    @include mq($from: tablet) {
      width: auto;
    }

  }

}
