/* ==========================================================================
   #Animation mixins 
   ========================================================================== */
/** 
 * Using the mixins looks like this:
 * 
 * @include keyframes(move-the-object) {
 *   0%   { left: 100px; }
 *   100% { left: 200px; }
 * }
 *
 * .object-to-animate {
 *   @include animation('move-the-object .5s 1', 'move-the-object-again .5s 1 .5s');
 * } 
 */
@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
      $animations: #{$animations + nth($animate, $i)};

      @if $i < $max {
          $animations: #{$animations + ", "};
      }
  }
  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations;
}

@mixin animation-name($name...) {
  -webkit-animation-name: $name;
  -moz-animation-name:    $name;
  -o-animation-name:      $name;
  animation-name:         $name;
}

@mixin animation-duration($duration...) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration:    $duration;
  -o-animation-duration:      $duration;
  animation-duration:         $duration;
}

@mixin animation-delay($delay...) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay:    $delay;
  -o-animation-delay:      $delay;
  animation-delay:         $delay;
}

@mixin animation-timing-function($timing...) {
  -webkit-animation-timing-function: $timing;
  -moz-animation-timing-function:    $timing;
  -o-animation-timing-function:      $timing;
  animation-timing-function:         $timing;
}

@mixin animation-fill-mode($direction) {
  -webkit-animation-fill-mode: $direction;
  -moz-animation-fill-mode:    $direction;
  -o-animation-fill-mode:      $direction;
  animation-fill-mode:         $direction;
}

@mixin animation-iteration-count($count) {
  -webkit-animation-iteration-count: $count;
  -moz-animation-iteration-count:    $count;
  -o-animation-iteration-count:      $count;
  animation-iteration-count:         $count;
}



@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
      @content;
  }
  @-moz-keyframes #{$animationName} {
      @content;
  }
  @-o-keyframes #{$animationName} {
      @content;
  }
  @keyframes #{$animationName} {
      @content;
  }
}