
/* ==========================================================================
   #STEPPER NAV
   ========================================================================== */

$stepper-nav-label-font-size:         $font-size-h4 !default;
$stepper-nav-label-line-height:       $input-field-height !default;
$stepper-nav-label-font-weight:       $font-weight-h4 !default;
$stepper-nav-arrow-icon-size:         $icon-size-large !default;
$stepper-nav-arrow-color:             $color-text-link !default;
$stepper-nav-arrow-color-disabled:    $color-text-disabled !default;
$stepper-nav-shadow:                  0 0 1px 3px rgba($color-background-primary, $opacity-3) !default;

// Private framework only
$c-stepper-nav-transition:            $text-color-transition, $background-color-transition, $box-shadow-transition !default;


/**
 * Previous/Next style navigation buttons (chevron icon).

    <   TEXT LABEL   >

 * HTML:

    <nav class="c-stepper-nav [ js-stepper-nav ]">
      <span class="c-stepper-nav__label">MONTH</span>

      <a href="#prev" id="js-prevMonth" class="c-stepper-nav__arrow nav--prev [ js-stepper-nav-btn ]" aria-label="Previous">
        <svg class="c-svg c-svg--chevron-left-24" aria-hidden="true" focusable="false"><use xlink:href="#c-svg--chevron-left-24"></use></svg>
      </a>
      <a href="#next" id="js-nextMonth" class="c-stepper-nav__arrow nav--next is-disabled [ js-stepper-nav-btn ]" aria-label="Next">
        <svg class="c-svg c-svg--chevron-right-24" aria-hidden="true" focusable="false"><use xlink:href="#c-svg--chevron-right-24"></use></svg>
      </a>
    </nav>

 *
 */

.c-stepper-nav {
  @include display-flex();
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  width: var(--aura-c-stepper-nav-width, auto);

  @include mq($from: tablet) {
    @include animation(aniStayHidden 1s, aniFadeInDown 250ms 1s 1 ease-out);
  }

}


.c-stepper-nav__label {
  @include font-size($stepper-nav-label-font-size, $stepper-nav-label-line-height);
  font-weight: var(--aura-c-stepper-nav-label-font-weight, $stepper-nav-label-font-weight);
  text-align: center;
  flex-grow: 1;
  order: 2;
}


.c-stepper-nav__arrow {
  width:  var(--aura-c-stepper-nav-arrow-icon-size, $stepper-nav-arrow-icon-size);
  height: var(--aura-c-stepper-nav-arrow-icon-size, $stepper-nav-arrow-icon-size);
  text-align: center;
  border-radius: $border-radius-small;
  transition: $c-stepper-nav-transition;

  .c-svg {
    display: block;
    top: 0;
    width:  100%;
    height: 100%;
    @include translateX(0);
    transition-duration: 0.3s;
    transition-property: transform, color;
    transition-timing-function: ease-out;
    color: var(--aura-c-stepper-nav-arrow-color, $stepper-nav-arrow-color);
  }

  &.nav--prev {
    order: 1;
  }

  &.nav--next {
    order: 3;
  }

  &:hover {
    text-decoration: none;
    
    &.nav--prev {
      .c-svg {
        @include mq($from: desktop) {
          @include translateX(-20%);
        }
      }
    }

    &.nav--next {
      .c-svg {
        @include mq($from: desktop) {
          @include translateX(20%);
        }
      }
    }

  }

  &:focus,
  &.is-focus,
  &:focus-within {
    outline: none;
    box-shadow: var(--aura-c-stepper-nav-shadow, $stepper-nav-shadow);
  }

  &.is-disabled {

    &,
    &:hover {
      cursor: default;
      text-decoration: none;
      background-color: transparent;

      .c-svg {
        color: var(--aura-c-stepper-nav-arrow-color-disabled, $stepper-nav-arrow-color-disabled);
        transition: none;
        @include translateX(0);
      }
    }

  }

}




/* Variations
========================================================================== */
.c-stepper-nav--month {
  min-width: 222px;
}