/* Radius
   ========================================================================== */
$border-radius-small:       3px !default;
$border-radius-medium:      6px !default;
$border-radius-large:       12px !default;
$border-radius-circle:      50% !default;
$border-radius-pill:        15rem !default;

$alert-border-radius:       $border-radius-small !default;
$toast-border-radius:       0 !default;
$badge-border-radius:       $border-radius-pill !default;
$button-border-radius:      $border-radius-pill !default;
$card-border-radius:        $border-radius-medium !default;
$dialog-border-radius:      $border-radius-medium !default;
$form-input-border-radius:  0 !default;
$tooltip-border-radius:     $border-radius-small !default;
$pagination-radius-border:  $border-radius-small !default;
$popover-border-radius:     $border-radius-medium !default;
$dropdown-border-radius:    $button-border-radius !default;
